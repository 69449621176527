import React, { Component } from 'react';

import DropdownList from '../utils/DropdownList';
import { getLanguageOptions } from '../../form-helpers';

export default class SelectLanguage extends Component {
    state = {
        items: [],
        isFetching: true
    };

    componentDidMount() {
        getLanguageOptions().then(options => {
            this.setState({
                items: options,
                isFetching: false
            });
        });
    }

    render() {
        const { items, isFetching } = this.state;

        return (
            <DropdownList
                items={items}
                isLoading={isFetching}
                useVirtualList={false}
                clearable={false}
                {...this.props}
            />
        );
    }
}
