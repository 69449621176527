import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function ArticleDetailsClose({ bg, onClick }) {
    return (
        <div
            className={cx(
                'article-row-details-close',
                'text-center',
                'c-hand',
                bg
            )}
            onClick={onClick}
        >
            <i className="icon icon-cross" />
        </div>
    );
}

ArticleDetailsClose.propTypes = {
    bg: PropTypes.string,
    onClick: PropTypes.func
};
