import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IconContext } from 'react-icons';
// import { unpackCatalog } from 'lingui-i18n';

import './main.css';
import './style.scss';
import Root from './Root';
import configureStore from './store/configureStore';
import rootSaga from './sagas';
// TODO: ripristinare serviceWorker
// import * as serviceWorker from './serviceWorker';

export const store = configureStore();
store.runSaga(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <IconContext.Provider value={{ className: 'react-icons' }}>
            <Root />
        </IconContext.Provider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
