import React, { Component, Fragment } from 'react';
import { Trans } from '@lingui/react';
// import truncate from 'lodash/truncate';

import ClickOutside from './utils/ClickOutside';

// function formatPlaceholder(placeholder) {
//     if (placeholder.length > 33) {
//         return truncate(placeholder);
//     }

//     return placeholder;
// }

export default class AttributeLogicWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
    }

    handleToggleMenu = () => {
        this.props.onLoad(this.props.attribute.key);
        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    };

    handleChange = e => {
        this.props.onChange(this.props.attribute.key, e.target.value);

        this.setState({
            isOpen: false
        });
    };

    render() {
        const { isOpen } = this.state;
        const { attribute, value, label } = this.props;

        // console.warn(attribute);

        return (
            <ClickOutside
                onClickOutside={() => this.setState({ isOpen: false })}
            >
                <div className="form-group">
                    <div className="tooltip" data-tooltip={label}>
                        <label className="form-label label-attributes text-primary">
                            {label}
                        </label>
                    </div>
                    <div className="dropdownList has-icon-right">
                        <div className="inputWrapper">
                            <input style={{ width: 0 }} />
                            {value !== 'all' && (
                                <span className="label label-rounded label-primary">
                                    {value === 'yes' ? (
                                        <Trans id="yes" />
                                    ) : (
                                        <Trans id="no" />
                                    )}{' '}
                                    <i
                                        className="form-icon icon icon-cross c-hand"
                                        style={{ right: '1rem' }}
                                        onClick={() =>
                                            this.handleChange({
                                                target: { value: 'all' }
                                            })
                                        }
                                    />
                                </span>
                            )}
                            <i
                                className={`form-icon icon icon-arrow-${
                                    isOpen ? 'up' : 'down'
                                } c-hand`}
                                onClick={this.handleToggleMenu}
                            />
                        </div>
                        {isOpen && (
                            <div className="dropdownList-menu">
                                <div className="dropdownList-menu-item">
                                    {attribute.isFetching ||
                                    attribute.data === null ? (
                                        <div className="text-center">
                                            <i className="form-icon loading" />
                                        </div>
                                    ) : (
                                        <div className="form-group form-group-dropdown">
                                            {attribute.data.count_true > 0 && (
                                                <Fragment>
                                                    <label className="form-radio">
                                                        <input
                                                            type="radio"
                                                            name="bool"
                                                            value="yes"
                                                            checked={
                                                                value === 'yes'
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <i className="form-icon" />{' '}
                                                        <Trans id="yes" />
                                                    </label>
                                                    <br />
                                                </Fragment>
                                            )}
                                            {attribute.data.count_false > 0 && (
                                                <Fragment>
                                                    <label className="form-radio">
                                                        <input
                                                            type="radio"
                                                            name="bool"
                                                            value="no"
                                                            checked={
                                                                value === 'no'
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                        <i className="form-icon" />{' '}
                                                        <Trans id="no" />
                                                    </label>
                                                    <br />
                                                </Fragment>
                                            )}
                                            <label className="form-radio">
                                                <input
                                                    type="radio"
                                                    name="bool"
                                                    value="all"
                                                    checked={value === 'all'}
                                                    onChange={this.handleChange}
                                                />
                                                <i className="form-icon" />{' '}
                                                <Trans id="all" />
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ClickOutside>
        );
    }
}
