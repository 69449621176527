import React from 'react';
import PropTypes from 'prop-types';
import { withI18n } from '@lingui/react';
import cx from 'classnames';

import FieldHoc from './FieldHoc';

const InputField = React.forwardRef(
    ({ input, meta, inputType, placeholder, className, i18n, min, max, ...rest }, ref) => {
        // console.warn(ref);
        // console.warn(input);

        return (
            <input
                ref={rest.forwardRef}
                {...input}
                type={inputType}
                placeholder={i18n._(placeholder) + (rest.mandatory ? '*' : '')}
                min={min}
                max={max}
                className={cx(className, {
                    'is-error': meta.error && meta.touched,
                })}
            />
        );
    }
);

InputField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    inputType: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
};

InputField.defaultProps = {
    inputType: 'text',
    className: 'form-input',
};

const InputFieldWithI18n = withI18n()(InputField);

export default FieldHoc(InputFieldWithI18n);
