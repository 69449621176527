import React, { Component } from 'react';
import { withI18n, Trans, i18nMark } from '@lingui/react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { toast } from 'react-toastify';

import InputField from './form/InputField';
import SubmitButton from './form/SubmitButton';
import { changePassword } from '../api';

function validate(values) {
    let errors = {};

    if (!values.password) {
        errors.password = i18nMark('validation:error:password');
    }

    if (!values.password_confirm) {
        errors.password_confirm = i18nMark('validation:error:password:confirm');
    }

    if (values.password !== values.password_confirm) {
        errors.password_confirm = i18nMark('validation:error:password:confirm:mismatch');
    }

    return errors;
}

const passwordLabel = i18nMark('password');
const passwordConfirmLabel = i18nMark('password:confirm');

export class ChangePasswordModal extends Component {
    componentDidMount() {
        setTimeout(() => {
            this.form.elements[0].focus();
        }, 0);
    }

    onSubmit = async (data) => {
        const { i18n } = this.props;

        try {
            await changePassword(this.props.userId, data);

            toast(i18n._('change:password:success'), {
                position: 'bottom-right',
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
            });

            setTimeout(() => {
                this.props.onClose();
            }, 500);
        } catch (err) {
            let errorMessage = i18n._('error:unexpected');

            if (err.response) {
                if (err.response.status === 409) {
                    errorMessage = i18n._('change:password:error:stale');
                }
            } else if (err.request) {
                errorMessage = i18n._('error:server:generic');
            }

            return {
                [FORM_ERROR]: errorMessage,
            };
        }
    };

    render() {
        const { onClose } = this.props;

        return (
            <div className="modal active" id="change-password-id">
                <span className="modal-overlay" aria-label="Close" />
                <div className="modal-container">
                    <div className="modal-header">
                        <div className="modal-title text-center h5">
                            <Trans id="change:password" />
                            <span
                                className="btn btn-clear float-right"
                                aria-label="Close"
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <div className="modal-body" id="change-password">
                        <div className="content">
                            <div className="container">
                                <Form
                                    onSubmit={this.onSubmit}
                                    validate={validate}
                                    render={({
                                        handleSubmit,
                                        pristine,
                                        // hasValidationErrors,
                                        submitError,
                                        submitting,
                                        submitSucceeded,
                                        // ...rest
                                    }) => {
                                        // console.warn(rest);

                                        return (
                                            <form
                                                ref={(form) => (this.form = form)}
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="form-group">
                                                    <Field
                                                        name="password"
                                                        component={InputField}
                                                        placeholder={passwordLabel}
                                                        inputType="password"
                                                        className="form-input input-lg"
                                                        mandatory={true}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <Field
                                                        name="password_confirm"
                                                        component={InputField}
                                                        placeholder={passwordConfirmLabel}
                                                        inputType="password"
                                                        className="form-input input-lg"
                                                        mandatory={true}
                                                    />
                                                </div>
                                                <SubmitButton
                                                    submitting={submitting}
                                                    disabled={pristine || submitSucceeded}
                                                >
                                                    <Trans id="confirm" />
                                                </SubmitButton>
                                                {submitError && (
                                                    <div className="bg-error mt-2 p-2 text-center">
                                                        {submitError}
                                                    </div>
                                                )}
                                            </form>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withI18n()(ChangePasswordModal);
