import React, { Component } from 'react';
import { connect } from 'react-redux';

import SectorsTree from '../components/SectorsTree';
import { actions } from '../actions/sectorsActions';
import { selectors } from '../reducers/sectorsReducer';
import { selectors as appSelectors } from '../reducers/appReducer';

class SectorsTreeContainer extends Component {
    render() {
        return <SectorsTree {...this.props} />;
    }
}

function mapStateToProps(state) {
    return {
        data: selectors.getTree(state),
        language: appSelectors.getLanguage(state),
        selectedItem: selectors.getSelectedItem(state),
        isFetching: selectors.getIsFetching(state)
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        onItemClick: (id, depth) => {
            dispatch(actions.toggleTreeItem(id, depth));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SectorsTreeContainer);
