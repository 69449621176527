import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link, NavLink, Route /*, withRouter*/ } from 'react-router-dom';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import cx from 'classnames';

import HeaderSearch from '../containers/HeaderSearch';
// import NavLink from './NavLink';
import { selectors } from '../reducers/userReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { authLogout /*, updateUser*/ } from '../actions/userActions';
import { changeLanguage, toggleSidebar } from '../actions/appActions';
import { getAssetURL, getServerVersion } from '../api';
// import { getLocaleFromLanguageCode } from '../intl-helpers';

export class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverVersion: null,
        };
    }

    async componentDidMount() {
        const res = await getServerVersion();

        this.setState({
            serverVersion: res.data,
        });
    }

    handleLogout = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        this.props.logout();
    };

    getUserName() {
        const { user } = this.props;

        if (user === null) {
            return '';
        }

        return `${user.nome} ${user.cognome}`;
    }

    render() {
        const {
            user,
            /*langCode,*/ appName,
            toggleSidebar,
            isSidebarOpen,
            location,
            showLists,
            enableExportPanel,
        } = this.props;

        // console.log(match);
        // console.log(location);

        const adminRoutes = [
            <MenuItem
                key="users"
                onClick={() => {
                    this.props.history.push('/app/users');
                }}
            >
                <NavLink to="/app/users" className="c-hand" activeClassName="active">
                    <Trans id="User management" />
                </NavLink>
            </MenuItem>,
            <MenuItem
                key="userGroups"
                onClick={() => {
                    this.props.history.push('/app/userGroups');
                }}
            >
                <NavLink to="/app/userGroups" className="c-hand" activeClassName="active">
                    <Trans id="User Groups management" />
                </NavLink>
            </MenuItem>,
            <MenuItem
                key="processes"
                onClick={() => {
                    this.props.history.push('/app/processes');
                }}
            >
                <NavLink to="/app/processes" className="c-hand" activeClassName="active">
                    <Trans id="Brand management" />
                </NavLink>
            </MenuItem>,
            <MenuItem
                key="customize"
                onClick={() => {
                    this.props.history.push('/app/customize');
                }}
            >
                <NavLink to="/app/customize" className="c-hand" activeClassName="active">
                    <Trans id="preferences management" />
                </NavLink>
            </MenuItem>,
        ];

        return (
            <header
                className="fixed top-0 flex items-center flex-wrap h-auto w-screen z-10 bg-primary"
                // style={{ backgroundColor: '#3692f9' }}
            >
                <section className="flex-grow lg:hidden pl-1 flex items-center mb-1">
                    <Link to="/app" className="inline-block">
                        <img
                            src={getAssetURL(`logo_header.png?t=${new Date().getTime()}`)}
                            alt="Datapool Logo"
                            height={40}
                            style={{ margin: '4px 2px 0 0', maxHeight: '40px' }}
                        />
                    </Link>
                    <span className="text-lg text-center text-white truncate overflow-hidden text-ellipsis ml-2 w-[55vw]">
                        {appName}
                    </span>
                </section>
                <div className="flex items-center lg:w-1/4">
                    <section className="hidden lg:block flex-grow text-center lg:text-left lg:flex-grow-0 ml-1 lg:ml-auto">
                        <Link to="/app" className="inline-block">
                            <img
                                src={getAssetURL(`logo_header.png?t=${new Date().getTime()}`)}
                                alt="Datapool Logo"
                                height={40}
                                style={{ margin: '4px 2px 0 0', maxHeight: '40px' }}
                            />
                        </Link>
                    </section>
                    <section>
                        <Link
                            to="/app"
                            className="hidden lg:inline-block navbar-brand c-hand text-lg"
                            style={{ textDecoration: 'none' }}
                        >
                            {appName}
                        </Link>
                    </section>
                </div>
                {/* <div className="lg:hidden flex-grow-0 pl-1">
                    {location.pathname !== '/app' ? (
                        // {location.pathname !== '/app' && location.pathname !== '/app/lists' ? (
                        <Link to="/app" className="btn text-primary">
                            <i className="icon icon-back"></i>
                        </Link>
                    ) : (
                        <button className="btn" onClick={toggleSidebar}>
                            <i
                                className={cx('icon', {
                                    'icon-menu': !isSidebarOpen,
                                    'icon-cross': isSidebarOpen,
                                })}
                            ></i>
                        </button>
                    )}
                </div> */}
                <Route exact={true} path="/app">
                    <HeaderSearch
                        isSidebarOpen={isSidebarOpen}
                        location={location}
                        toggleSidebar={toggleSidebar}
                    />
                </Route>
                <div
                    className={cx('text-right', {
                        'xl:flex-grow': location.pathname !== '/app',
                    })}
                >
                    <Menu
                        onMenuChange={({ open }) => {
                            if (open && isSidebarOpen && window.innerWidth < 1024) {
                                toggleSidebar();
                            }
                        }}
                        menuClassName="menu text-left"
                        menuButton={
                            <MenuButton className="btn btn-noeffects pr-0 lg:pr-4 bg-primary !text-white">
                                <i className="icon icon-people" />{' '}
                                <span className="hidden lg:inline">{this.getUserName()} </span>
                                <i className="icon icon-caret" />
                            </MenuButton>
                        }
                    >
                        <SubMenu
                            label={() => (
                                // eslint-disable-next-line
                                <a>
                                    <Trans id="My profile" />
                                </a>
                            )}
                            direction="left"
                            offsetX={1}
                        >
                            <MenuItem
                                onClick={() => {
                                    this.props.history.push('/app/profile');
                                }}
                            >
                                <NavLink
                                    to="/app/profile"
                                    className="c-hand"
                                    activeClassName="active"
                                >
                                    <Trans id="preferences" />
                                </NavLink>
                            </MenuItem>
                            {window.innerWidth >= 1024 && (
                                <MenuItem
                                    onClick={() => {
                                        this.props.history.push('/app/customize-table');
                                    }}
                                >
                                    <NavLink
                                        to="/app/customize-table"
                                        className="c-hand"
                                        activeClassName="active"
                                    >
                                        <Trans id="customize table" />
                                    </NavLink>
                                </MenuItem>
                            )}
                        </SubMenu>
                        {enableExportPanel && (
                            <MenuItem>
                                <NavLink
                                    to="/app/exports"
                                    className="c-hand"
                                    activeClassName="active"
                                >
                                    <Trans id="Download" />
                                </NavLink>
                            </MenuItem>
                        )}
                        {showLists && (
                            <MenuItem
                                onClick={() => {
                                    this.props.history.push('/app/lists');
                                }}
                            >
                                <NavLink
                                    to="/app/lists"
                                    className="c-hand"
                                    activeClassName="active"
                                >
                                    <Trans id="collections" />
                                </NavLink>
                            </MenuItem>
                        )}
                        {user && user.ruolo === 'admin' ? adminRoutes : []}
                        <MenuItem onClick={this.handleLogout}>
                            {/* eslint-disable-next-line */}
                            <a className="c-hand">
                                <Trans id="logout" />
                            </a>
                        </MenuItem>
                        <MenuItem className="border-t" disabled={true}>
                            <a style={{ fontSize: '0.6rem', lineHeight: '0.8rem' }}>
                                Versione <b>{process.env.REACT_APP_VERSION}</b>{' '}
                                {this.state.serverVersion && (
                                    <span>
                                        (Server <b>{this.state.serverVersion}</b>)
                                    </span>
                                )}
                            </a>
                        </MenuItem>
                    </Menu>
                    {/* <div className="dropdown dropdown-right dropdown-nav">
                        <span className="btn btn-primary dropdown-toggle c-hand" tabIndex="0">
                            <i className="icon icon-people" /> {this.getUserName()}{' '}
                            <i className="icon icon-caret" />
                        </span>
                        <ul className="menu">
                            <li className="menu-item">
                                <NavLink
                                    to="/app/profile"
                                    className="c-hand"
                                    activeClassName="active"
                                >
                                    <Trans id="profile" />
                                </NavLink>
                            </li>
                            {user && user.ruolo === 'admin' && (
                                <>
                                    <li className="menu-item">
                                        <NavLink
                                            to="/app/users"
                                            className="c-hand"
                                            activeClassName="active"
                                        >
                                            <Trans id="User management" />
                                        </NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink
                                            to="/app/userGroups"
                                            className="c-hand"
                                            activeClassName="active"
                                        >
                                            <Trans id="User Groups management" />
                                        </NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink
                                            to="/app/processes"
                                            className="c-hand"
                                            activeClassName="active"
                                        >
                                            <Trans id="Brand management" />
                                        </NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink
                                            to="/app/customize"
                                            className="c-hand"
                                            activeClassName="active"
                                        >
                                            <Trans id="customization" />
                                        </NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink
                                            to="/app/customize-table"
                                            className="c-hand"
                                            activeClassName="active"
                                        >
                                            <Trans id="customize table" />
                                        </NavLink>
                                    </li>
                                </>
                            )}
                            <li className="menu-item">
                                <a className="c-hand" href="/app/login" onClick={this.handleLogout}>
                                    <Trans id="logout" />
                                </a>
                            </li>
                        </ul>
                    </div> */}
                </div>
                {/*this.state.showChangeLanguageModal && (
                    <ChangeLanguageModal
                        onClose={this.closeModal}
                        langCode={langCode}
                        user={user}
                        onChangeLanguage={this.handleChangeLanguage}
                    />
                )*/}
            </header>
        );
    }
}

Header.propTypes = {
    fullTextFilter: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        user: selectors.getUser(state),
        // langCode: appSelectors.getLanguage(state),
        appName: appSelectors.getAppName(state),
        isSidebarOpen: appSelectors.getIsSidebarOpen(state),
        showLists: appSelectors.getShowLists(state),
        enableExportPanel: appSelectors.getEnableExportPanel(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout() {
            dispatch(authLogout());
        },
        changeLanguage(lang) {
            dispatch(changeLanguage(lang));
        },
        toggleSidebar() {
            dispatch(toggleSidebar());
        },
        /*updateUser(payload) {
            dispatch(updateUser(payload));
        }*/
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
