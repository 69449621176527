import { actionTypes } from '../actions/itemsActions';
import { createSelector } from 'reselect';
import { Record, /*Set,*/ Map, /*OrderedMap,*/ OrderedSet, List, fromJS } from 'immutable';

import { selectors as collectionsSelectors } from './collectionsReducer';

const ItemRecord = Record({
    id: null,
    marca_id: null,
    marca: null,
    codice_articolo: null,
    descrizione_articolo: null,
    prezzo: null,
    unita_ordine: null,
    unita_ordine_id: null,
    details: Map({}),
    immagine_prodotto: null,
    modello: null,
    linee: [],
    serie: [],
    prezzo_attuale: null,
    in_collection: null,
    isSelected: null,
    children: [],
    metadata: Map({}),
});

const StateRecord = Record({
    currentPage: 0,
    errorMessage: null,
    isFetching: false,
    itemsById: Map({}),
    itemsId: OrderedSet([]),
    selectedItems: List([]),
    lastSearchHash: null,
    pages: 0,
    per_page: 40,
    total: 0,
    serverTotal: 0,
    sortBy: null,
    sortDirection: 'asc',
    selectAllChecked: false,
});

export const DEFAULT_STATE = StateRecord();

function fetchStart(state) {
    return state.set('isFetching', true);
}

function fetchSuccess(state, action) {
    const {
        payload: { results, meta },
    } = action;

    let items = Map({});
    let ids = OrderedSet([]);

    results.forEach((item) => {
        // TODO: effettuare alcune operazioni sui dati? per esempio tornare l'immagine in maniera più easy, invece di ripetere la logica nei vari componenti?
        items = items.set(item.id, ItemRecord(item));
        ids = ids.add(item.id);
    });

    return state.merge({
        isFetching: false,
        total: meta.total,
        serverTotal: meta.serverTotal ? meta.serverTotal : meta.total,
        pages: Math.ceil(meta.total / state.per_page),
        itemsById: items,
        itemsId: ids,
    });
}

function fetchFail(state, action) {
    return state.set('isFetching', false);
}

function resetItems(state) {
    return StateRecord({
        sortBy: state.sortBy,
        sortDirection: state.sortDirection,
    });
}

function changePage(state, action) {
    return state.set('currentPage', action.payload);
}

function toggleDirection(current) {
    if (current === 'asc') {
        return 'desc';
    }

    return 'asc';
}

function sortBy(state, action) {
    const attr = action.payload;
    const previousSort = state.get('sortBy');

    return state.merge({
        sortBy: attr,
        sortDirection: attr === previousSort ? toggleDirection(state.get('sortDirection')) : 'asc',
    });
}

function setLastSearchHash(state, action) {
    return state.set('lastSearchHash', action.payload);
}

function toggleSelectedItemHandler(state, action) {
    const currentItems = state.get('selectedItems');

    const index = currentItems.indexOf(action.payload);

    const newSelectedItems =
        index !== -1 ? currentItems.remove(index) : currentItems.push(action.payload);

    return state.set('selectedItems', newSelectedItems);
}

function toggleAllSelectedItemsHandler(state) {
    if (state.get('selectAllChecked')) {
        return state.set('selectedItems', List([])).set('selectAllChecked', false);
    }

    return state.set('selectedItems', state.get('itemsId').toList()).set('selectAllChecked', true);
}

function resetSelectedItemsHandler(state) {
    return state.set('selectedItems', List([]));
}

const handlers = {
    [actionTypes.FETCH_ITEMS_START]: fetchStart,
    [actionTypes.FETCH_ITEMS_SUCCESS]: fetchSuccess,
    [actionTypes.FETCH_ITEMS_FAIL]: fetchFail,
    [actionTypes.RESET_ITEMS]: resetItems,
    [actionTypes.CHANGE_ITEMS_PAGE]: changePage,
    [actionTypes.SORT_ITEMS_BY]: sortBy,
    [actionTypes.SET_LAST_SEARCH_HASH]: setLastSearchHash,
    [actionTypes.TOGGLE_SELECTED_ITEM]: toggleSelectedItemHandler,
    [actionTypes.TOGGLE_ALL_SELECTED_ITEMS]: toggleAllSelectedItemsHandler,
    [actionTypes.RESET_SELECTED_ITEMS]: resetSelectedItemsHandler,
};

export default function itemsReducer(state = DEFAULT_STATE, action) {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
}

function getItems(state) {
    return state.items.itemsId.map((id) => {
        return state.items.itemsById.get(id);
    });
}

function getCustomItems(state) {
    return state.items.itemsId.map((id) => {
        const item = state.items.itemsById.get(id).toJS();

        const properties = item.children.reduce((obj, property) => {
            obj[property.property] = {
                value: property.value,
                children: property.children,
            };

            return obj;
        }, {});

        return fromJS({
            id: item.id,
            ...item.metadata,
            ...properties,
        });
    });
}

function getSelectedItems(state) {
    return state.items.selectedItems;
}

export const selectors = {
    getCurrentPage(state) {
        return state.items.currentPage;
    },
    getPerPage(state) {
        return state.items.per_page;
    },
    getError(state) {
        return state.items.errorMessage;
    },
    getItem(state, id) {
        return state.items.itemsById[id];
    },
    getItems: createSelector([getItems, getSelectedItems], (items, selectedItems) => {
        return items.map((i) => {
            return i.set('isSelected', selectedItems.includes(i.id));
        });
    }),
    getCustomItems: createSelector([getCustomItems, getSelectedItems], (items, selectedItems) => {
        return items.map((i) => {
            // console.log(i.id);
            return i.set('isSelected', selectedItems.includes(i.get('id')));
        });
    }),
    getItemsTotal(state) {
        return state.items.total;
    },
    getItemsServerTotal(state) {
        return state.items.serverTotal;
    },
    getPagesTotal(state) {
        return state.items.pages;
    },
    getSorting(state) {
        return {
            sortBy: state.items.sortBy,
            sortDirection: state.items.sortDirection,
        };
    },
    hasError(state) {
        return state.items.errorMessage !== null;
    },
    getIsFetching(state) {
        return state.items.isFetching;
    },
    getLastSearchHash(state) {
        return state.items.lastSearchHash;
    },
    getSelectedItems,
    getSelectAllChecked(state) {
        return state.items.selectAllChecked;
    },
    getItemsWithCollections: createSelector(
        [
            getItems,
            collectionsSelectors.getItemsCollections,
            collectionsSelectors.getEngagedCollection,
        ],
        (items, itemsCollections, engagedCollection) => {
            // console.warn(itemsCollections, engagedCollection);

            return items.map((i) => {
                const c = itemsCollections.get(i.id);

                const inCollection = c ? c.includes(engagedCollection) : false;

                return i.set('in_collection', inCollection);
            });
        }
    ),
    getCustomItemsWithCollections: createSelector(
        [
            getCustomItems,
            collectionsSelectors.getItemsCollections,
            collectionsSelectors.getEngagedCollection,
        ],
        (items, itemsCollections, engagedCollection) => {
            // console.warn(itemsCollections, engagedCollection);

            return items.map((i) => {
                const c = itemsCollections.get(i.get('id'));

                const inCollection = c ? c.includes(engagedCollection) : false;

                return i.set('in_collection', inCollection);
            });
        }
    ),
};
