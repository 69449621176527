import { all } from 'redux-saga/effects';

import appSaga from './appSaga';
import collectionsSaga from './collectionsSaga';
import filtersSaga from './filtersSaga';
import itemsSaga from './itemsSaga';
import productClassesSaga from './productClassesSaga';
import sectorsSaga from './sectorsSaga';
import userSaga from './userSaga';
import usersSaga from './usersSaga';

export default function* rootSaga() {
    yield all([
        ...appSaga,
        ...collectionsSaga,
        ...filtersSaga,
        ...itemsSaga,
        ...productClassesSaga,
        ...sectorsSaga,
        ...userSaga,
        ...usersSaga
    ]);
}
