import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import AppLoader from './components/utils/AppLoader';
import ErrorPage from './components/ErrorPage';
import history from './history';
import { selectors } from './reducers/userReducer';
import { updateUser, authSuccessfull } from './actions/userActions';
import { refresh, getUtente, setToken } from './api';

const ExternalLoginRedirectorComponent = withRouter(props => {
    const [loginError, setLoginError] = useState(false);

    useEffect(() => {
        if (props.user) {
            return history.push('/app');
        }

        const params = new URLSearchParams(window.location.search);

        if (params.has('security') === false || params.has('id') === false) {
            history.push('/login');

            return;
        }

        (async () => {
            try {
                const resToken = await refresh(params.get('security'));

                props.dispatch(updateUser(resToken.data));
                setToken(resToken.data.token);

                const resUser = await getUtente(params.get('id'));

                props.dispatch(authSuccessfull({ utente: resUser.data, ...resToken.data }));

                history.push('/app');
            } catch (err) {
                setLoginError(true);
            }
        })();
        // eslint-disable-next-line
    }, []);

    if (loginError) {
        return (
            <ErrorPage
                showBackButton={false}
                showReloadButton={true}
                message="Si è verificato un problema imprevisto"
            />
        );
    }

    return <AppLoader />;
});

function mapStateToProps(state) {
    return {
        user: selectors.getUser(state)
    };
}

export default connect(mapStateToProps)(ExternalLoginRedirectorComponent);
