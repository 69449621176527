import React from 'react';
import truncate from 'lodash/truncate';
import cx from 'classnames';
import Highlighter from 'react-highlight-words';

export default function LimitText({ limit, text, textClass, highlight }) {
    return (
        <span className={cx('tooltip', textClass)} data-tooltip={text}>
            <Highlighter
                searchWords={[highlight]}
                autoEscape={true}
                textToHighlight={truncate(text, {
                    length: limit
                })}
            />
        </span>
    );
}
