import React, { Component } from 'react';
import { Trans } from '@lingui/react';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });

        console.error(error);
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h4>
                        <Trans id="ops" />
                    </h4>
                    <p>
                        <Trans id="error:unexpected" />
                    </p>
                </div>
            );
        }

        return this.props.children;
    }
}
