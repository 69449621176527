import { actionTypes } from '../actions/collectionsActions';
import { Record, Map, OrderedSet, List, fromJS } from 'immutable';
import { createSelector } from 'reselect';
// import fuzzaldrin from 'fuzzaldrin-plus';
import matchSorter from 'match-sorter';
import isArray from 'lodash/isArray';

const COLLECTION_PER_PAGE = 100;

export const CollectionRecord = Record({
    articoli_number: null,
    codice: null,
    descrizione: null,
    id: null,
    note: null,
});

const FiltersRecord = Record({
    code: '',
    description: '',
});

export const StateRecord = Record({
    currentPage: 0,
    errorMessage: null,
    isFetching: false,
    itemsById: Map({}),
    itemsId: OrderedSet([]),
    // pages: 0,
    // per_page: 5,
    total: 0,
    sortBy: null,
    sortDirection: 'asc',
    collectionEngaged: null,
    collectionShown: null,
    lastSelectedCollection: null,
    itemsCollections: Map({}),
    filters: FiltersRecord(),
    collectionViewActive: false,
});

export const DEFAULT_STATE = StateRecord();

function fetchStart(state, action) {
    return state.set('isFetching', true);
}

function fetchSuccess(state, action) {
    const {
        payload: { results },
    } = action;

    let items = Map({});
    let ids = OrderedSet([]);

    results.forEach((item) => {
        items = items.set(item.id, CollectionRecord(item));
        ids = ids.add(item.id);
    });

    return state.merge({
        isFetching: false,
        total: results.length,
        itemsById: items,
        itemsId: ids,
    });
}

function fetchFail(state, action) {
    return state.set('isFetching', false);
}

function updateCollection(state, action) {
    const { payload } = action;

    return state.setIn(['itemsById', payload.id], CollectionRecord(payload));
}

function changePage(state, action) {
    return state.set('currentPage', action.payload);
}

function toggleDirection(current) {
    if (current === 'asc') {
        return 'desc';
    }

    return 'asc';
}

function sortBy(state, action) {
    const attr = action.payload;
    const previousSort = state.get('sortBy');

    return state.merge({
        currentPage: 0,
        sortBy: attr,
        sortDirection: attr === previousSort ? toggleDirection(state.get('sortDirection')) : 'asc',
    });
}

function toggleEngageCollection(state, action) {
    const id = action.payload;

    if (id === state.get('collectionEngaged')) {
        return state.set('collectionEngaged', null);
    }

    return state.set('collectionEngaged', id);
}

function toggleShownCollection(state, action) {
    const id = action.payload;

    if (id === state.get('collectionShown')) {
        return state.set('collectionShown', null);
    }

    return state.set('collectionShown', id);
}

function setShownCollection(state, action) {
    return state.set('collectionShown', action.payload);
}

function resetShownCollection(state, action) {
    return state.set('collectionShown', null);
}

function setItemsCollections(state, action) {
    if (!isArray(action.payload.data)) {
        return state;
    }

    const data = action.payload.data.reduce((res, item) => {
        res[item.articolo_id] = item.collezione_ids;

        return res;
    }, {});

    return state.set('itemsCollections', fromJS(data));
}

function resetItemsCollections(state) {
    return state.set('itemsCollections', Map({}));
}

function toggleItemFromCollection(state, action) {
    const { collectionId, itemId } = action.payload;
    const path = ['itemsById', collectionId, 'articoli_number'];

    const currentItemsNumber = state.getIn(path);

    let op = null;

    const partialUpdatedState = state.updateIn(['itemsCollections', itemId], (collections) => {
        if (typeof collections === 'undefined') {
            op = 'add';
            return List([collectionId]);
        }

        if (collections.includes(collectionId)) {
            const index = collections.indexOf(collectionId);

            op = 'remove';

            return collections.remove(index);
        }

        op = 'add';

        return collections.push(collectionId);
    });

    const newItemsNumber = op === 'remove' ? currentItemsNumber - 1 : currentItemsNumber + 1;

    return partialUpdatedState.setIn(path, newItemsNumber);
}

function updateCollectionItemsNumber(state, action) {
    const { collectionId, number } = action.payload;

    return state.setIn(['itemsById', collectionId, 'articoli_number'], number);
}

function deleteCollection(state, action) {
    let newState = state
        .updateIn(['itemsId'], (ids) => ids.delete(action.payload))
        .updateIn(['total'], (tot) => tot - 1);

    if (state.collectionEngaged === action.payload) {
        newState = newState.set('collectionEngaged', null);
    }

    if (state.collectionShown === action.payload) {
        newState = newState.set('collectionShown', null);
    }

    return newState;
}

function updateCollectionFilter(state, action) {
    return state.setIn(['filters', action.payload.key], action.payload.value);
}

function setLastSelectedCollectionHandler(state, action) {
    return state.set('lastSelectedCollection', action.payload);
}

function setCollectionViewActiveHandler(state, action) {
    return state.set('collectionViewActive', action.payload);
}

const handlers = {
    [actionTypes.FETCH_COLLECTIONS_START]: fetchStart,
    [actionTypes.FETCH_COLLECTIONS_SUCCESS]: fetchSuccess,
    [actionTypes.FETCH_COLLECTIONS_FAIL]: fetchFail,
    [actionTypes.UPDATE_COLLECTION_SUCCESS]: updateCollection,
    [actionTypes.CHANGE_COLLECTIONS_PAGE]: changePage,
    [actionTypes.SORT_COLLECTIONS_BY]: sortBy,
    [actionTypes.TOGGLE_ENGAGE_COLLECTION]: toggleEngageCollection,
    [actionTypes.TOGGLE_SHOWN_COLLECTION]: toggleShownCollection,
    [actionTypes.SET_SHOWN_COLLECTION]: setShownCollection,
    [actionTypes.RESET_SHOWN_COLLECTION]: resetShownCollection,
    [actionTypes.SET_ITEMS_COLLECTIONS]: setItemsCollections,
    [actionTypes.RESET_ITEMS_COLLECTIONS]: resetItemsCollections,
    [actionTypes.TOGGLE_ITEM_FROM_COLLECTION]: toggleItemFromCollection,
    [actionTypes.UPDATE_COLLECTION_ITEMS_NUMBER]: updateCollectionItemsNumber,
    [actionTypes.DELETE_COLLECTION_SUCCESS]: deleteCollection,
    [actionTypes.UPDATE_COLLECTION_FILTER]: updateCollectionFilter,
    [actionTypes.SET_LAST_SELECTED_COLLECTION]: setLastSelectedCollectionHandler,
    [actionTypes.SET_COLLECTION_VIEW_ACTIVE]: setCollectionViewActiveHandler,
};

export default function collectionsReducer(state = DEFAULT_STATE, action) {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
}

function getItem(state, id) {
    return state.collections.itemsById.get(id);
}

function getItems(state) {
    return state.collections.itemsId.map((id) => {
        return state.collections.itemsById.get(id);
    });
}

function getSorting(state) {
    return {
        sortBy: state.collections.sortBy,
        sortDirection: state.collections.sortDirection,
    };
}

// function getPerPage(state) {
//     return state.collections.per_page;
// }

function getCurrentPage(state) {
    return state.collections.currentPage;
}

function getEngagedCollection(state) {
    return state.collections.collectionEngaged;
}

function getFilters(state) {
    return state.collections.filters;
}

const getFilteredItems = createSelector([getItems, getFilters], (items, filters) => {
    if (filters.code === '' && filters.description === '') {
        return items;
    }

    let filtered = items.toJS();

    if (filters.code !== '') {
        filtered = matchSorter(filtered, filters.code, {
            keys: ['codice'],
            threshold: matchSorter.rankings.CONTAINS,
        });
    }

    if (filters.description !== '') {
        filtered = matchSorter(filtered, filters.description, {
            keys: ['descrizione'],
            threshold: matchSorter.rankings.CONTAINS,
        });
    }

    return new OrderedSet(filtered.map((f) => CollectionRecord(f)));
});

const getFilteredItemsTotal = createSelector(getFilteredItems, (items) => items.count());

const getPagesTotal = createSelector(getFilteredItemsTotal, (total) =>
    Math.ceil(total / COLLECTION_PER_PAGE)
);

export const selectors = {
    getError(state) {
        return state.collections.errorMessage;
    },
    getItem,
    getCollectionItemsNumber: createSelector([getItem], (collection) => collection.articoli_number),
    getItems,
    getDisplayItems: createSelector(
        [getFilteredItems, getCurrentPage, getSorting],
        (items, currentPage, sorting) => {
            const start = currentPage * COLLECTION_PER_PAGE;
            const end = (currentPage + 1) * COLLECTION_PER_PAGE;

            let result = items;

            if (sorting.sortBy !== null) {
                result = result.sortBy((i) => {
                    const term = i.get(sorting.sortBy)
                        ? i.get(sorting.sortBy).toLowerCase()
                        : 'zzz';

                    return term;
                });

                if (sorting.sortDirection === 'desc') {
                    result = result.reverse();
                }
            }

            return result.slice(start, end);

            // TODO: trick per tornare sempre la collezione ingaggiata anche se non tornerebbe tra le collezioni di una data pagina
            // in seguito ad un ordinamento
            // if (engagedCollectionId !== null)  {
            //     const engagedCollection = items.find(item => item.id === engagedCollectionId);

            //     result = result.add(engagedCollection);
            // }

            // return result;
        }
    ),
    getItemsTotal(state) {
        return state.collections.total;
    },
    getFilteredItemsTotal,
    getCurrentPage,
    getPagesTotal,
    // getPagesTotal(state) {
    //     return Math.ceil(state.collections.total / COLLECTION_PER_PAGE);
    // },
    getSorting,
    hasError(state) {
        return state.collections.errorMessage !== null;
    },
    getIsFetching(state) {
        return state.collections.isFetching;
    },
    getEngagedCollection,
    getShownCollection(state) {
        return state.collections.collectionShown;
    },
    getItemsCollections(state) {
        return state.collections.itemsCollections;
    },
    getFilters,
    getLastSelectedCollection(state) {
        return state.collections.lastSelectedCollection;
    },
    getCollectionViewActive(state) {
        return state.collections.collectionViewActive;
    },
};
