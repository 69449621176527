import React from 'react';

export default function Loader({ height = null }) {
    return (
        <div
            className="loading-wrapper loading loading-lg"
            style={{ height }}
        />
    );
}
