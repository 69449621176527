import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withI18n, Trans } from '@lingui/react';
// import { Portal } from 'react-portal';
import get from 'lodash/get';

import DropdownListFilterWrapper from './utils/DropdownListFilterWrapper';
import { actions as productClassesActions } from '../actions/productClassesActions';
import SectorsTree from '../containers/SectorsTree';
import { actions } from '../actions/filtersActions';
import { actions as sectorsActions } from '../actions/sectorsActions';
import * as constants from '../constants';
import { getOptionLabelLocalized, getLocaleFromLanguageCode } from '../intl-helpers';
import AttributeNumericWrapper from './AttributeNumericWrapper';
// import ListiniModal from './ListiniModal';
// import DropdownMultiple from './utils/DropdownMultiple';

function getListiniLabel(language) {
    return function (option) {
        const baseLabel = getOptionLabelLocalized(language)(option);

        if (option.extra && option.extra.codice_revisione !== '00') {
            const locale = getLocaleFromLanguageCode(language);

            const descrizione = get(
                option.extra,
                `descrizione_revisione.${locale}`,
                option.extra.codice_revisione
            );

            return `${baseLabel} - ${descrizione}`;
        }

        return baseLabel;
    };
}

export class Sidebar extends Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         showListiniModal: false
    //     };
    // }

    render() {
        const {
            i18n,
            classificationFilter,
            brandFilter,
            isBrandFixed,
            // goingOutOfProductionFilter,
            // optionalsFilter,
            // replacementsFilter,
            // outOfProductionFilter,
            statusFilter,
            priceRangeFilter,
            fuoriCartaceoFilter,
            height,
            language,
            showPriceRangeFilter,
            lineFilter,
            modelFilter,
            seriesFilter,
            listiniFilter,
            productClassFilter,
            validFilterOptions,
        } = this.props;

        // console.log(fuoriCartaceoFilter);

        // console.log(validFilterOptions);
        // console.log(brandFilter.toJS());

        // let typeSelectedOptions = [];

        // if (optionalsFilter.value) {
        //     typeSelectedOptions.push({ value: constants.OPTIONALS_FILTER, label: 'Optionals' });
        // }

        // if (replacementsFilter.value) {
        //     typeSelectedOptions.push({ value: constants.REPLACEMENTS_FILTER, label: 'Ricambi' });
        // }

        return (
            <div>
                {isBrandFixed !== true && (
                    <Fragment>
                        {/* <h6 className="text-uppercase text-primary">
                            <Trans id="choose:by:brand" />
                        </h6> */}
                        <DropdownListFilterWrapper
                            filter={brandFilter}
                            placeholder={i18n._('filter:brand')}
                            disabled={isBrandFixed}
                            itemToString={getOptionLabelLocalized(language)}
                            onChange={(item) =>
                                this.props.dispatch(actions.changeBrandFilterValue(item))
                            }
                            multiple={true}
                        />
                    </Fragment>
                )}
                {/* {statusFilter.options.count() > 0 && ( */}
                <DropdownListFilterWrapper
                    filter={statusFilter}
                    placeholder={i18n._('filter:status')}
                    itemToString={getOptionLabelLocalized(language)}
                    onChange={(item) =>
                        this.props.dispatch(actions.setFilterValue(constants.STATUS_FILTER, item))
                    }
                    multiple={true}
                />
                <DropdownListFilterWrapper
                    filter={fuoriCartaceoFilter}
                    placeholder={i18n._('filter:fuoriCartaceo')}
                    validFilterOptions={null}
                    itemToString={(option) => {
                        const label = get(option, 'label');

                        return i18n._(label);
                    }}
                    onChange={(item) =>
                        this.props.dispatch(
                            actions.setFilterValue(constants.FUORI_CARTACEO_FILTER, item)
                        )
                    }
                />
                {/* <div className="columns">
                    <div className="column col-12">
                        <DropdownMultiple
                            items={[
                                { value: constants.OPTIONALS_FILTER, label: 'Optionals' },
                                { value: constants.REPLACEMENTS_FILTER, label: 'Ricambi' }
                            ]}
                            placeholder="Filtro tipologia articoli"
                            selectedItem={typeSelectedOptions}
                            onChange={item => {
                                if (item === null) {
                                    this.props.dispatch(
                                        actions.setFilterValue(constants.OPTIONALS_FILTER, false)
                                    );

                                    this.props.dispatch(
                                        actions.setFilterValue(constants.REPLACEMENTS_FILTER, false)
                                    );

                                    return;
                                }

                                if (item.value === constants.OPTIONALS_FILTER) {
                                    this.props.dispatch(
                                        actions.setFilterValue(
                                            constants.OPTIONALS_FILTER,
                                            !optionalsFilter.value
                                        )
                                    );
                                }

                                if (item.value === constants.REPLACEMENTS_FILTER) {
                                    this.props.dispatch(
                                        actions.setFilterValue(
                                            constants.REPLACEMENTS_FILTER,
                                            !replacementsFilter.value
                                        )
                                    );
                                }
                            }}
                            onLoad={() => {}}
                            clearable={true}
                            showPlaceholder={true}
                        />
                    </div>
                </div> */}
                {/* )} */}
                {/* {statusFilter.options.count() === 0 && <div style={{ height: '32px' }} />} */}
                {/* <div className="columns">
                    <div className="column col-6">
                        <div className="form-compact">
                            <div className="form-group">
                                <label className="form-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={optionalsFilter.value}
                                        disabled={!optionalsFilter.enabled}
                                        onChange={e =>
                                            this.props.dispatch(
                                                actions.setFilterValue(
                                                    constants.OPTIONALS_FILTER,
                                                    e.target.checked
                                                )
                                            )
                                        }
                                    />
                                    <i className="form-icon" /> <Trans id="optionals" />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="column col-6">
                        <div className="form-compact">
                            <div className="form-group">
                                <label className="form-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={replacementsFilter.value}
                                        disabled={!replacementsFilter.enabled}
                                        onChange={e =>
                                            this.props.dispatch(
                                                actions.setFilterValue(
                                                    constants.REPLACEMENTS_FILTER,
                                                    e.target.checked
                                                )
                                            )
                                        }
                                    />
                                    <i className="form-icon" /> <Trans id="replacements" />
                                </label>
                            </div>
                        </div>
                    </div>
                </div> */}
                {listiniFilter.options.count() > 0 &&
                    (isBrandFixed || brandFilter.value.count() === 1) && (
                        <DropdownListFilterWrapper
                            filter={listiniFilter}
                            placeholder={i18n._('filter:listini')}
                            useVirtualList={false}
                            itemToString={getListiniLabel(language)}
                            itemToSortString={(o) => {
                                const code =
                                    o.extra.codice_listino + '_' + o.extra.codice_revisione;

                                // console.log(code, o.extra.descrizione_revisione.it_IT);
                                return code;
                            }}
                            onChange={(item) =>
                                this.props.dispatch(
                                    actions.setFilterValue(constants.LISTINI_FILTER, item)
                                )
                            }
                            multiple={true}
                        />
                    )}
                {seriesFilter.options.count() > 0 && (
                    <DropdownListFilterWrapper
                        filter={seriesFilter}
                        placeholder={i18n._('filter:series')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.dispatch(
                                actions.setFilterValue(constants.SERIES_FILTER, item)
                            )
                        }
                        multiple={true}
                    />
                )}
                {lineFilter.options.count() > 0 && (
                    <DropdownListFilterWrapper
                        filter={lineFilter}
                        placeholder={i18n._('filter:line')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.dispatch(actions.setFilterValue(constants.LINE_FILTER, item))
                        }
                        multiple={true}
                    />
                )}
                {modelFilter.options.count() > 0 && (
                    <DropdownListFilterWrapper
                        filter={modelFilter}
                        placeholder={i18n._('filter:model')}
                        useVirtualList={false}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.dispatch(
                                actions.setFilterValue(constants.MODEL_FILTER, item)
                            )
                        }
                        multiple={true}
                    />
                )}
                {showPriceRangeFilter && (
                    <React.Fragment>
                        <div className="divider" />
                        <AttributeNumericWrapper
                            label="Prezzo min/max"
                            rangeSuffix="€"
                            freeStep={true}
                            min={priceRangeFilter.min}
                            max={priceRangeFilter.max}
                            start={priceRangeFilter.value[0]}
                            end={priceRangeFilter.value[1]}
                            isActive={
                                priceRangeFilter.value[0] !== 0 || priceRangeFilter.value[1] !== 0
                            }
                            attribute={{}}
                            onLoad={() => {}}
                            onClear={() =>
                                this.props.dispatch(
                                    actions.setFilterValue(constants.PRICE_RANGE_FILTER, [0, 0])
                                )
                            }
                            onChange={(_, range) =>
                                this.props.dispatch(
                                    actions.setFilterValue(constants.PRICE_RANGE_FILTER, range)
                                )
                            }
                        />
                    </React.Fragment>
                )}
                <div className="divider" />
                <h6 className="text-uppercase text-primary">
                    <Trans id="choose:by:taxonomy" />
                </h6>
                <DropdownListFilterWrapper
                    filter={classificationFilter}
                    placeholder={i18n._('filter:classification')}
                    clearable={false}
                    itemToString={getOptionLabelLocalized(language)}
                    onChange={(item) =>
                        this.props.dispatch(sectorsActions.setClassificationValue(item))
                    }
                    useVirtualList={false}
                />
                {classificationFilter.value?.code === 'etim' && (
                    <>
                        <h6 className="text-uppercase text-primary">Classe prodotto</h6>
                        <DropdownListFilterWrapper
                            filter={productClassFilter}
                            validFilterOptions={validFilterOptions}
                            placeholder={i18n._('filter:product:class')}
                            itemToString={(option) => {
                                return option
                                    ? `${option.code ? option.code : '-'} ${getOptionLabelLocalized(
                                          language
                                      )(option)}`
                                    : '';
                            }}
                            itemToSortString={getOptionLabelLocalized(language)}
                            onChange={(item) =>
                                this.props.dispatch(
                                    productClassesActions.setProductClassValue(item)
                                )
                            }
                            useVirtualList={false}
                        />
                    </>
                )}
                <SectorsTree
                    sidebarHeight={height}
                    classificationType={classificationFilter.value?.code}
                />
            </div>
        );
    }
}

Sidebar.propTypes = {
    classificationFilter: PropTypes.object.isRequired,
    brandFilter: PropTypes.object.isRequired,
    i18n: PropTypes.object,
    isBrandFixed: PropTypes.bool,
    // optionalsFilter: PropTypes.object.isRequired,
    // replacementsFilter: PropTypes.object.isRequired,
    statusFilter: PropTypes.object.isRequired,
    showPriceRangeFilter: PropTypes.bool.isRequired,
    height: PropTypes.number,
    language: PropTypes.string,
    validFilterOptions: PropTypes.object,
};

export default withI18n()(Sidebar);
