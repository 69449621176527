import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withI18n, Trans } from '@lingui/react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { parse, format } from 'date-fns';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import * as Tabs from '@radix-ui/react-tabs';
import find from 'lodash/find';

import { deleteUtente, updateUser } from '../api';
import { selectors } from '../reducers/sectorsReducer';
import SelectGroup from './select/SelectGroup';
import {
    GrantTabs,
    getSelectedNodesFromGroup,
    buildGrantsForApi,
    // getCustomClassificationOption,
} from './UserGroupEditModal';
// import { getUtente } from '../api';

function localizeDate(date) {
    if (!date) {
        return '-';
    }

    return format(parse(date), 'DD/MM/YYYY HH:mm:ss');
}

export class UserDetailsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_group_id: props.user.group_id,
            isUpdatingUserGroup: false,
        };
    }

    // async componentDidMount() {
    //     const res = await getUtente(this.props.userId);

    //     this.setState({
    //         isFetching: false,
    //         user: res.data
    //     });
    // }

    handleDelete = () => {
        const { i18n } = this.props;

        Swal.fire({
            icon: 'warning',
            text: i18n._('confirm:delete:user'),
            showCancelButton: true,
            confirmButtonText: i18n._('confirm'),
            confirmButtonColor: '#007bff',
            cancelButtonText: i18n._('cancel'),
        }).then((res) => {
            if (res.value === true) {
                deleteUtente(this.props.user.id).then((res) => {
                    toast(i18n._('User deleted'), {
                        position: 'bottom-right',
                        type: toast.TYPE.SUCCESS,
                    });

                    this.props.onClose();

                    setTimeout(() => {
                        this.props.onDeleteUtente(this.props.user.id);
                    }, 500);
                });
            }
        });
    };

    updateGroup = async (data) => {
        this.setState({
            isUpdatingUserGroup: true,
        });

        // console.log(data);

        const grants = buildGrantsForApi(data);

        const res = await updateUser(this.props.user.id, {
            group_id: this.state.user_group_id ? this.state.user_group_id.value : null,
            preferences: this.props.user.preferences || [],
            grants,
        });

        // TODO: gestire errori

        const msg = this.props.i18n._('user:userGroup:updated');

        toast(msg, {
            position: 'bottom-right',
            type: toast.TYPE.SUCCESS,
        });

        this.props.onUpdateUserGroup(this.props.user.id, res.data.data);

        this.setState({
            isUpdatingUserGroup: false,
        });

        this.props.onClose();
    };

    render() {
        const { onClose, onEnableUtente, onToggleLockUtente, user } = this.props;
        // const { isFetching } = this.state;

        let submit;

        // console.log(user.grants);

        const viewMarcheGrant = find(user.grants, { code: 'view_marche' });
        // const viewPropertiesGrant = find(user.grants, { code: 'view_properties' });
        const viewProductTreesGrant = find(user.grants, { code: 'view_product_trees' });
        const viewProductsGrant = find(user.grants, {
            code: 'view_products',
        });

        return (
            <div className="modal active" id="user-details-modal-id">
                <span className="modal-overlay" aria-label="Close" />
                <div className="modal-container min-h-[90vh] min-w-[90vw]">
                    <div className="modal-header">
                        <div className="modal-title text-center h5">
                            {user.titolo} {user.nome} {user.cognome}
                            <span
                                className="btn btn-clear float-right"
                                aria-label="Close"
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <div className="modal-body flex-grow" id="user-details-modal">
                        <Tabs.Root className="TabsRoot" defaultValue="general">
                            <Tabs.List className="TabsList" aria-label="Seleziona">
                                <Tabs.Trigger className="TabsTrigger" value="general">
                                    Generale
                                </Tabs.Trigger>
                                <Tabs.Trigger className="TabsTrigger" value="autorizations">
                                    Autorizzazioni
                                </Tabs.Trigger>
                                <Tabs.Trigger className="TabsTrigger" value="group">
                                    Gruppo
                                </Tabs.Trigger>
                            </Tabs.List>
                            <Form
                                onSubmit={this.updateGroup}
                                // validate={validate}
                                initialValues={{
                                    not_allowed_marca_ids:
                                        viewMarcheGrant?.parameters?.not_allowed_marca_ids || [],
                                    properties_ids: getSelectedNodesFromGroup(user) || [],
                                    view_product_trees_ids:
                                        viewProductTreesGrant?.parameters?.product_trees || [],
                                    view_products_ids:
                                        viewProductsGrant?.parameters
                                            .custom_classifications || [],
                                }}
                                render={({ handleSubmit, form }) => {
                                    submit = handleSubmit;

                                    const formValues = form.getState().values;

                                    return (
                                        <form className="form-horizontal" onSubmit={handleSubmit}>
                                            <Tabs.Content className="TabsContent" value="general">
                                                <div className="w-[75%]">
                                                    <div className="grid grid-cols-12 gap-1">
                                                        <div className="col-span-4 xl:col-span-2 text-right">
                                                            <Trans id="title" />:
                                                        </div>
                                                        <div className="col-span-8 xl:col-span-10 text-bold">
                                                            {user.titolo}
                                                        </div>
                                                        <div className="col-span-4 xl:col-span-2 text-right">
                                                            <Trans id="name" />:
                                                        </div>
                                                        <div className="col-span-8 xl:col-span-10 text-bold">
                                                            {user.nome}
                                                        </div>
                                                        <div className="col-span-4 xl:col-span-2 text-right">
                                                            <Trans id="surname" />:
                                                        </div>
                                                        <div className="col-span-8 xl:col-span-10 text-bold">
                                                            {user.cognome}
                                                        </div>
                                                        <div className="col-span-4 xl:col-span-2 text-right">
                                                            Email:
                                                        </div>
                                                        <div className="col-span-8 xl:col-span-10 text-bold">
                                                            {user.email}
                                                        </div>
                                                        <div className="col-span-4 xl:col-span-2 text-right">
                                                            <Trans id="company" />:
                                                        </div>
                                                        <div className="col-span-8 xl:col-span-10 text-bold">
                                                            {user.ragione_sociale}
                                                        </div>
                                                        <div className="col-span-4 xl:col-span-2 text-right">
                                                            <Trans id="activity" />:
                                                        </div>
                                                        <div className="col-span-8 xl:col-span-10 text-bold">
                                                            {user.attivita}
                                                        </div>
                                                        <div className="col-span-4 xl:col-span-2 text-right">
                                                            <Trans id="province" />:
                                                        </div>
                                                        <div className="col-span-8 xl:col-span-10 text-bold">
                                                            {user.nome_provincia}
                                                        </div>
                                                        <div className="mt-4 col-span-full xl:col-span-2 xl:text-right">
                                                            <Trans id="Registration date" />:
                                                        </div>
                                                        <div className="col-span-full xl:col-span-10 text-bold">
                                                            {localizeDate(user.created_at)}
                                                        </div>
                                                        <div className="col-span-full xl:col-span-2 xl:text-right">
                                                            <Trans id="enabled" />:{' '}
                                                            <label className="form-switch form-switch-inline form-switch-slim">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={user.enabled}
                                                                    disabled={user.enabled}
                                                                    onChange={() =>
                                                                        onEnableUtente(user.id)
                                                                    }
                                                                />
                                                                <i className="form-icon" />
                                                            </label>
                                                            <Trans id="at" />:
                                                        </div>
                                                        <div className="col-span-full xl:col-span-10 text-bold">
                                                            {localizeDate(user.enabled_at)}
                                                        </div>
                                                        <div className="col-span-full xl:col-span-2 xl:text-right">
                                                            <Trans id="blocked" />:{' '}
                                                            <label className="form-switch form-switch-inline form-switch-slim">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={user.locked}
                                                                    onChange={() =>
                                                                        onToggleLockUtente(user.id)
                                                                    }
                                                                />
                                                                <i className="form-icon" />
                                                            </label>
                                                            <Trans id="at" />:
                                                        </div>
                                                        <div className="col-span-full xl:col-span-10 text-bold">
                                                            {localizeDate(user.locked_at)}
                                                        </div>
                                                        <div className="col-span-full xl:col-span-2 xl:text-right">
                                                            <Trans id="last:login" />:
                                                        </div>
                                                        <div className="col-span-10 text-bold">
                                                            {localizeDate(user.logged_at)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tabs.Content>
                                            <Tabs.Content
                                                className="TabsContent"
                                                value="autorizations"
                                            >
                                                <GrantTabs
                                                    formValues={formValues}
                                                    classificationFilter={
                                                        this.props.classificationFilter
                                                    }
                                                />
                                            </Tabs.Content>
                                            <Tabs.Content className="TabsContent" value="group">
                                                <div className="grid grid-cols-12 gap-1">
                                                    <div className="xl:col-span-4 col-span-8 text-bold">
                                                        <SelectGroup
                                                            selectedItem={this.state.user_group_id}
                                                            placeholder={this.props.i18n._(
                                                                'select:group'
                                                            )}
                                                            inputClassName="form-input"
                                                            onChange={(option) =>
                                                                this.setState({
                                                                    user_group_id: option,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Tabs.Content>
                                        </form>
                                    );
                                }}
                            />
                        </Tabs.Root>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-primary"
                            onClick={() => submit()}
                            disabled={this.state.isUpdatingUserGroup}
                        >
                            {this.state.isUpdatingUserGroup ? (
                                <Trans id="Please wait" />
                            ) : (
                                <Trans id="confirm:update" />
                            )}
                        </button>{' '}
                        <button className="btn btn-error" onClick={this.handleDelete}>
                            <Trans id="Delete user" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

UserDetailsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    onEnableUtente: PropTypes.func.isRequired,
    onToggleLockUtente: PropTypes.func.isRequired,
};

const UserDetailsModalWithI18n = withI18n()(UserDetailsModal);

function mapStateToProps(state) {
    return {
        classificationFilter: selectors.getFilter(state).toJS(),
    };
}

export default connect(mapStateToProps)(UserDetailsModalWithI18n);
