import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { selectors } from '../../reducers/userReducer';

function mapStateToProps(state) {
    return {
        user: selectors.getUser(state)
    };
}

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function MatchWhenRole(WrappedComponent, role) {
    function MatchWhenRoleComponent(props) {
        if (props.user !== null && props.user.ruolo === role) {
            return <WrappedComponent {...props} />;
        }

        return <Redirect to="/app" />;
    }

    MatchWhenRoleComponent.displayName = getDisplayName(WrappedComponent);

    return connect(mapStateToProps)(MatchWhenRoleComponent);
}

export default MatchWhenRole;
