import React, { Component } from 'react';
import { withI18n } from '@lingui/react';

import Tooltip from './utils/Tooltip';
import { AddToCollectionBtn } from './AddToCollectionModal';
// import Swal from 'sweetalert2';
export class CollectionControlsHeader extends Component {
    // handleDelete = (e) => {
    //     e.stopPropagation();

    //     const { i18n } = this.props;

    //     Swal.fire({
    //         icon: 'warning',
    //         text: i18n._('collection:confirm:remove:all:page:articles'),
    //         showCancelButton: true,
    //         confirmButtonText: i18n._('confirm'),
    //         confirmButtonColor: '#007bff',
    //         cancelButtonText: i18n._('cancel'),
    //     }).then((res) => {
    //         if (res.value === true) {
    //             this.props.onRemoveAll();
    //         }
    //     });
    // };

    render() {
        const { /*selectAllDisabled, enableAddItemsModal, */ onSelectAll, selectAllChecked, i18n } =
            this.props;

        return (
            <span className="flex items-center pt-0.5">
                <Tooltip content={i18n._('Add all page articles')} position="top">
                    <label
                        className="checkmark-container"
                        onClick={(e) => {
                            e.stopPropagation();
                            onSelectAll();
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={selectAllChecked}
                            onClick={(e) => e.stopPropagation()}
                            onChange={() => {}}
                        />
                        <span className="checkmark"></span>
                    </label>
                </Tooltip>
                {/* <span>
                    <Tooltip content={i18n._('Add all page articles')} position="top">
                        <label
                            className="form-checkbox form-checkbox-inline float-left"
                            // data-tooltip={i18n._('Add all page articles')}
                            style={{ top: 0, paddingRight: 0 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                // if (selectAllDisabled === false) {
                                onSelectAll();
                                // }
                            }}
                        >
                            <input
                                type="checkbox"
                                checked={selectAllChecked}
                                onClick={(e) => e.stopPropagation()}
                                onChange={() => {}}
                                // disabled={selectAllDisabled}
                            />
                            <i className="form-icon" />
                        </label>
                    </Tooltip>
                </span> */}
                <AddToCollectionBtn />
                {/* <Tooltip
                    content={i18n._('Remove all page articles from the collection')}
                    position="top"
                >
                    <i
                        className="icon icon-cross text-error c-hand"
                        onClick={this.handleDelete}
                    />
                </Tooltip> */}
            </span>
        );
    }
}

export default withI18n()(CollectionControlsHeader);
