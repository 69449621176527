import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import DropdownList from '../utils/DropdownList';
import FieldHoc from './FieldHoc';

const SelectField = ({ input, meta, placeholder, className, options, ...rest }) => {
    return (
        <DropdownList
            {...input}
            {...rest}
            selectedItem={input.value}
            placeholder={placeholder || input.name}
            items={options}
            inputClassName={cx(className, {
                // TODO: supporto touch???
                'is-error': meta.error && meta.touched
            })}
        />
    );
};

SelectField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    className: PropTypes.string
};

SelectField.defaultProps = {
    className: 'form-input',
    options: []
};

export default FieldHoc(SelectField);
