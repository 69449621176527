import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SelectAttivita from '../select/SelectAttivita';
import FieldHoc from './FieldHoc';

const SelectAttivitaField = ({
    input,
    meta,
    placeholder,
    className,
    options
}) => {
    return (
        <SelectAttivita
            {...input}
            selectedItem={input.value}
            placeholder={placeholder || input.name}
            inputClassName={cx(className, {
                // TODO: supporto touch???
                'is-error': meta.error && meta.touched
            })}
        />
    );
};

SelectAttivitaField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string
};

SelectAttivitaField.defaultProps = {
    className: 'form-input'
};

export default FieldHoc(SelectAttivitaField);
