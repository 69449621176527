import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DropdownList from '../utils/DropdownList';
import {
    getAttivitaOptions,
    getAttivitaUtentiOptions
} from '../../form-helpers';

export default class SelectAttivita extends Component {
    state = {
        items: [],
        isFetching: true
    };

    componentDidMount() {
        this.fetchOptions();
    }

    componentDidUpdate(prevProps) {
        if (this.props.filter !== prevProps.filter) {
            this.fetchOptions();
        }
    }

    fetchOptions() {
        const { type, filter } = this.props;

        const fn =
            type === 'all' ? getAttivitaOptions : getAttivitaUtentiOptions;

        fn({ q: filter }).then(options => {
            this.setState({
                items: options,
                isFetching: false
            });
        });
    }

    render() {
        const { items, isFetching } = this.state;

        return (
            <DropdownList
                items={items}
                isLoading={isFetching}
                {...this.props}
            />
        );
    }
}

SelectAttivita.propTypes = {
    type: PropTypes.oneOf(['all', 'user']),
    filter: PropTypes.string
};

SelectAttivita.defaultProps = {
    type: 'all'
};
