export const FETCH_PRODUCT_CLASS_ATTRIBUTES = 'FETCH_PRODUCT_CLASS_ATTRIBUTES';
export const FETCH_PRODUCT_CLASS_ATTRIBUTES_START =
    'FETCH_PRODUCT_CLASS_ATTRIBUTES_START';
export const FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS =
    'FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS';
export const FETCH_PRODUCT_CLASS_ATTRIBUTES_FAIL =
    'FETCH_PRODUCT_CLASS_ATTRIBUTES_FAIL';
export const RESET_PRODUCT_CLASS_ATTRIBUTE_FILTERS =
    'RESET_PRODUCT_CLASS_ATTRIBUTE_FILTERS';
export const RESET_PRODUCT_CLASS_ATTRIBUTES_FILTERS_ALL =
    'RESET_PRODUCT_CLASS_ATTRIBUTES_FILTERS_ALL';
export const TOGGLE_PRODUCT_CLASS_ATTRIBUTE_FILTER =
    'TOGGLE_PRODUCT_CLASS_ATTRIBUTE_FILTER';
export const RESET_PRODUCT_CLASS = 'RESET_PRODUCT_CLASS';
export const SET_PRODUCT_CLASS_VALUE = 'SET_PRODUCT_CLASS_VALUE';
export const SET_PRODUCT_CLASS_OPTIONS = 'SET_PRODUCT_CLASS_OPTIONS';
export const FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA =
    'FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA';
export const FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_START =
    'FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_START';
export const FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_SUCCESS =
    'FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_SUCCESS';
export const FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_FAIL =
    'FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_FAIL';
export const SET_PRODUCT_CLASS_ATTRIBUTE_LOGIC =
    'SET_PRODUCT_CLASS_ATTRIBUTE_LOGIC';
export const SET_PRODUCT_CLASS_ATTRIBUTE_NUMERIC =
    'SET_PRODUCT_CLASS_ATTRIBUTE_NUMERIC';

export function resetProductClassAttribute(key) {
    return {
        type: RESET_PRODUCT_CLASS_ATTRIBUTE_FILTERS,
        payload: key
    };
}

export function resetProductClassAttributeAll() {
    return {
        type: RESET_PRODUCT_CLASS_ATTRIBUTES_FILTERS_ALL
    };
}

export function toggleProductClassAttributeFilter(attribute, key) {
    return {
        type: TOGGLE_PRODUCT_CLASS_ATTRIBUTE_FILTER,
        payload: {
            attribute,
            key
        }
    };
}

export function setProductClassAttributeLogic(attribute, value) {
    return {
        type: SET_PRODUCT_CLASS_ATTRIBUTE_LOGIC,
        payload: {
            attribute,
            value
        }
    };
}

export function setProductClassAttributeNumeric(attribute, range) {
    return {
        type: SET_PRODUCT_CLASS_ATTRIBUTE_NUMERIC,
        payload: {
            attribute,
            range
        }
    };
}

export function fetchProductClassAttributes() {
    return {
        type: FETCH_PRODUCT_CLASS_ATTRIBUTES
    };
}

export function fetchProductClassAttributesStart() {
    return {
        type: FETCH_PRODUCT_CLASS_ATTRIBUTES_START
    };
}

export function fetchProductClassAttributesSuccess(response) {
    return {
        type: FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS,
        payload: response.data
    };
}

export function fetchProductClassAttributesFail(response) {
    return {
        type: FETCH_PRODUCT_CLASS_ATTRIBUTES_FAIL
        // TODO: portare errore nel payload
    };
}

export function fetchProductClassAttributeData(id) {
    return {
        type: FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA,
        payload: {
            id
        }
    };
}

export function fetchProductClassAttributeDataStart(id) {
    return {
        type: FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_START,
        payload: {
            id
        }
    };
}

export function fetchProductClassAttributeDataSuccess(
    id,
    response,
    requestHash
) {
    return {
        type: FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_SUCCESS,
        payload: {
            id,
            response,
            requestHash
        }
    };
}

export function fetchProductClassAttributeDataFail(id, response) {
    return {
        type: FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_FAIL,
        paylaod: {
            id,
            response
        }
    };
}

export function resetProductClass() {
    return {
        type: RESET_PRODUCT_CLASS
    };
}

export function setProductClassValue(value) {
    return {
        type: SET_PRODUCT_CLASS_VALUE,
        payload: value
    };
}

export function setProductClassOptions(options) {
    return {
        type: SET_PRODUCT_CLASS_OPTIONS,
        payload: options
    };
}

export const actionTypes = {
    RESET_PRODUCT_CLASS_ATTRIBUTE_FILTERS,
    RESET_PRODUCT_CLASS_ATTRIBUTES_FILTERS_ALL,
    TOGGLE_PRODUCT_CLASS_ATTRIBUTE_FILTER,
    FETCH_PRODUCT_CLASS_ATTRIBUTES,
    FETCH_PRODUCT_CLASS_ATTRIBUTES_START,
    FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS,
    FETCH_PRODUCT_CLASS_ATTRIBUTES_FAIL,
    FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA,
    FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_START,
    FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_SUCCESS,
    FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_FAIL,
    RESET_PRODUCT_CLASS,
    SET_PRODUCT_CLASS_VALUE,
    SET_PRODUCT_CLASS_OPTIONS,
    SET_PRODUCT_CLASS_ATTRIBUTE_LOGIC,
    SET_PRODUCT_CLASS_ATTRIBUTE_NUMERIC
};

export const actions = {
    resetProductClassAttribute,
    resetProductClassAttributeAll,
    toggleProductClassAttributeFilter,
    fetchProductClassAttributes,
    fetchProductClassAttributesStart,
    fetchProductClassAttributesSuccess,
    fetchProductClassAttributesFail,
    fetchProductClassAttributeData,
    fetchProductClassAttributeDataStart,
    fetchProductClassAttributeDataSuccess,
    fetchProductClassAttributeDataFail,
    resetProductClass,
    setProductClassValue,
    setProductClassOptions,
    setProductClassAttributeLogic,
    setProductClassAttributeNumeric
};
