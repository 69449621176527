import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Grid from './components/Grid';
import MatchWhenAuthorized from './components/routing/MatchWhenAuthorized';
import { bootstrapApp /*, hideLoadingSplashScreen*/ } from './actions/appActions';
import { selectors } from './reducers/appReducer';
import AppLoader from './components/utils/AppLoader';
import ErrorPage from './components/ErrorPage';
// import { selectors as userSelectors } from './reducers/userReducer';

class App extends Component {
    componentDidMount() {
        this.props.dispatch(bootstrapApp());
    }

    // componentDidUpdate(prevProps) {
    //     if (
    //         this.props.isBootstrapping === false &&
    //         prevProps.isBootstrapping === true
    //     ) {
    //         this.props.dispatch(hideLoadingSplashScreen());
    //     }
    // }

    isAppReady() {
        return this.props.isBootstrapping === false;
    }

    showLoader() {
        if (this.props.isSplashLoadingHidden === false) {
            return null;
        }

        return <AppLoader />;
    }

    render() {
        return (
            <Fragment>
                {this.isAppReady() ? (
                    this.props.isBootstrapFailed ? (
                        <ErrorPage showBackButton={false} showReloadButton={true} />
                    ) : (
                        <Grid />
                    )
                ) : (
                    this.showLoader()
                )}
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isBootstrapping: selectors.getIsBootstrapping(state),
        isSplashLoadingHidden: selectors.getIsLoadingSplashScreenHidden(state),
        isBootstrapFailed: selectors.getIsBootstrapFailed(state)
        // user: userSelectors.getUser(state)
    };
}

export default connect(mapStateToProps)(MatchWhenAuthorized(App));
