export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const CHANGE_USERS_PAGE = 'CHANGE_USERS_PAGE';
export const SORT_USERS_BY = 'SORT_USERS_BY';
export const SET_USERS_FILTER = 'SET_USERS_FILTER';
export const RESET_USERS_FILTERS = 'RESET_USERS_FILTERS';
export const ENABLE_USER = 'ENABLE_USER';
export const DELETE_USER = 'DELETE_USER';
export const TOGGLE_LOCK_USER = 'TOGGLE_LOCK_USER';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export function fetchUsers(options = {}) {
    return {
        type: FETCH_USERS,
        payload: options
    };
}

export function fetchUsersStart() {
    return {
        type: FETCH_USERS_START
    };
}

export function fetchUsersSuccess(response) {
    return {
        type: FETCH_USERS_SUCCESS,
        payload: response.data
    };
}

export function fetchUsersFail(response) {
    return {
        type: FETCH_USERS_FAIL
        // TODO: portare errore nel payload
    };
}

export function changePage(page) {
    return {
        type: CHANGE_USERS_PAGE,
        payload: page
    };
}

export function sortBy(attr) {
    return {
        type: SORT_USERS_BY,
        payload: attr
    };
}

export function setUsersFilter(key, value) {
    return {
        type: SET_USERS_FILTER,
        payload: {
            key,
            value
        }
    };
}

export function resetUsersFilters() {
    return {
        type: RESET_USERS_FILTERS
    };
}

export function enableUser(id) {
    return {
        type: ENABLE_USER,
        payload: id
    };
}

export function deleteUser(id) {
    return {
        type: DELETE_USER,
        payload: id
    };
}

export function toggleLockUser(id) {
    return {
        type: TOGGLE_LOCK_USER,
        payload: id
    };
}

export function updateUserData(id, data) {
    return {
        type: UPDATE_USER_DATA,
        payload: {
            id,
            data
        }
    };
}

export const actions = {
    fetchUsers,
    fetchUsersStart,
    fetchUsersSuccess,
    fetchUsersFail,
    changePage,
    sortBy,
    setUsersFilter,
    resetUsersFilters,
    enableUser,
    deleteUser,
    toggleLockUser,
    updateUserData
};

export const actionTypes = {
    FETCH_USERS,
    FETCH_USERS_START,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAIL,
    CHANGE_USERS_PAGE,
    SORT_USERS_BY,
    SET_USERS_FILTER,
    RESET_USERS_FILTERS,
    ENABLE_USER,
    DELETE_USER,
    TOGGLE_LOCK_USER,
    UPDATE_USER_DATA
};
