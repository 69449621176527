import { Record } from 'immutable';
import { actionTypes } from '../actions/userActions';

export const UserRecord = Record({
    id: null,
    nome: null,
    cognome: null,
    email: null,
    locale: null,
    ruolo: null,
    token: null,
    token_expire: null,
    refresh_token: null,
    preferences: null
});

export const DEFAULT_STATE = UserRecord();

function setUser(state, action) {
    const { payload } = action;

    return UserRecord({
        id: payload.utente.id,
        nome: payload.utente.nome,
        cognome: payload.utente.cognome,
        email: payload.utente.email,
        locale: payload.utente.locale,
        ruolo: payload.utente.ruolo,
        token: payload.token,
        token_expire: payload.token_expire,
        refresh_token: payload.refresh_token,
        preferences: payload.utente.preferences
    });
}

function authLogout() {
    return UserRecord();
}

function updateUser(state, action) {
    return state.merge(action.payload);
}

const handlers = {
    [actionTypes.SET_USER]: setUser,
    [actionTypes.UPDATE_USER]: updateUser,
    [actionTypes.AUTH_LOGOUT]: authLogout
};

export default function userReducer(state = DEFAULT_STATE, action) {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
}

export const selectors = {
    getUser(state) {
        if (state.user.id === null) {
            return null;
        }

        return state.user;
    },
    getUserToken(state) {
        return state.user.token;
    },
    getTokenExpire(state) {
        return state.user.token_expire;
    },
    getRefreshToken(state) {
        return state.user.refresh_token;
    }
};
