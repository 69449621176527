import { connect } from 'react-redux';

import RowBlock from '../components/RowBlock';
// import { selectors } from '../reducers/appReducer';

function mapStateToProps(state) {
    return {
        // productInfoMap: selectors.getProductInfoMap(state)
    };
}

export default connect(mapStateToProps)(RowBlock);
