import React, { Component } from 'react';
import { connect } from 'react-redux';

import EditCollectionModal from '../components/EditCollectionModal';
import { selectors, CollectionRecord } from '../reducers/collectionsReducer';
import { selectors as userSelectors } from '../reducers/userReducer';
import { actions } from '../actions/collectionsActions';

export class EditCollectionModalContainer extends Component {
    render() {
        return <EditCollectionModal {...this.props} />;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: userSelectors.getUser(state),
        collection:
            ownProps.collectionId === null
                ? new CollectionRecord({})
                : selectors.getItem(state, ownProps.collectionId)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateCollectionSuccess(response) {
            dispatch(actions.updateCollectionSuccess(response));
        },
        fetchCollections() {
            dispatch(actions.fetchCollections());
        },
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCollectionModalContainer);
