import React, { Component } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../components/Sidebar';
import { selectors } from '../reducers/filtersReducer';
import { selectors as productClassReducerSelectors } from '../reducers/productClassesReducer';
import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import * as constants from '../constants';

function getValidFilterOptions(node) {
    if (node === null) {
        return null;
    }

    if (node.children.size === 0) {
        return null;
    }

    return node.children.map(l => l.id);
}

export class SidebarContainer extends Component {
    render() {
        return <Sidebar {...this.props} />;
    }
}

function mapStateToProps(state) {
    const productProperties = appSelectors.getProductProperties(state);

    const classificationType = sectorsSelectors.getFilterValue(state);
    let selectedNode = null;

    if (classificationType === constants.ETIM_CLASSIFICATION) {
        selectedNode = sectorsSelectors.getSelectedTreeItemRecord(state);
    }

    return {
        classificationFilter: sectorsSelectors.getFilter(state),
        brandFilter: selectors.getFilter(state, constants.BRAND_FILTER),
        // listiniFilter: selectors.getFilter(state, constants.LISTINI_FILTER),
        // optionalsFilter: selectors.getFilter(state, constants.OPTIONALS_FILTER),
        priceRangeFilter: selectors.getFilter(state, constants.PRICE_RANGE_FILTER),
        isBrandFixed: appSelectors.getIsBrandFixed(state),
        language: appSelectors.getLanguage(state),
        showPriceRangeFilter: !!productProperties.prezzo_listino,
        // outOfProductionFilter: selectors.getFilter(
        //     state,
        //     constants.OUT_OF_PRODUCTION_FILTER
        // ),
        // goingOutOfProductionFilter: selectors.getFilter(
        //     state,
        //     constants.GOING_OUT_OF_PRODUCTION_FILTER
        // ),
        // replacementsFilter: selectors.getFilter(state, constants.REPLACEMENTS_FILTER),
        statusFilter: selectors.getFilter(state, constants.STATUS_FILTER),
        lineFilter: selectors.getFilter(state, constants.LINE_FILTER),
        modelFilter: selectors.getFilter(state, constants.MODEL_FILTER),
        seriesFilter: selectors.getFilter(state, constants.SERIES_FILTER),
        listiniFilter: selectors.getFilter(state, constants.LISTINI_FILTER),
        fuoriCartaceoFilter: selectors.getFilter(state, constants.FUORI_CARTACEO_FILTER),
        productClassFilter: productClassReducerSelectors.getFilter(state),
        validFilterOptions: getValidFilterOptions(selectedNode)
    };
}

export default connect(mapStateToProps)(SidebarContainer);
