export const FETCH_COLLECTIONS = 'FETCH_COLLECTIONS';
export const FETCH_COLLECTIONS_START = 'FETCH_COLLECTIONS_START';
export const FETCH_COLLECTIONS_SUCCESS = 'FETCH_COLLECTIONS_SUCCESS';
export const FETCH_COLLECTIONS_FAIL = 'FETCH_COLLECTIONS_FAIL';
// export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const UPDATE_COLLECTION_SUCCESS = 'UPDATE_COLLECTION_SUCCESS';
// export const UPDATE_COLLECTION_FAIL = 'UPDATE_COLLECTION_FAIL';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS';
export const CHANGE_COLLECTIONS_PAGE = 'CHANGE_COLLECTIONS_PAGE';
export const SORT_COLLECTIONS_BY = 'SORT_COLLECTIONS_BY';
export const TOGGLE_ENGAGE_COLLECTION = 'TOGGLE_ENGAGE_COLLECTION';
export const TOGGLE_SHOWN_COLLECTION = 'TOGGLE_SHOWN_COLLECTION';
export const SET_SHOWN_COLLECTION = 'SET_SHOWN_COLLECTION';
export const RESET_SHOWN_COLLECTION = 'RESET_SHOWN_COLLECTION';
export const ADD_SELECTED_ITEMS_TO_COLLECTION = 'ADD_SELECTED_ITEMS_TO_COLLECTION';
export const TOGGLE_ITEM_FROM_COLLECTION = 'TOGGLE_ITEM_FROM_COLLECTION';
export const ADD_ALL_ITEMS_TO_COLLECTION = 'ADD_ALL_ITEMS_TO_COLLECTION';
export const REMOVE_PAGED_ITEMS_FROM_COLLECTION = 'REMOVE_PAGED_ITEMS_FROM_COLLECTION';
export const DUPE_COLLECTION = 'DUPE_COLLECTION';
export const SET_ITEMS_COLLECTIONS = 'SET_ITEMS_COLLECTIONS'; // ancora necessari?
export const RESET_ITEMS_COLLECTIONS = 'RESET_ITEMS_COLLECTIONS'; // ancora necessari?
export const UPDATE_COLLECTION_ITEMS_NUMBER = 'UPDATE_COLLECTION_ITEMS_NUMBER';
export const UPDATE_COLLECTION_FILTER = 'UPDATE_COLLECTION_FILTER';
export const SET_LAST_SELECTED_COLLECTION = 'SET_LAST_SELECTED_COLLECTION';
export const SET_COLLECTION_VIEW_ACTIVE = 'SET_COLLECTION_VIEW_ACTIVE';

export function fetchCollections(options = {}) {
    return {
        type: FETCH_COLLECTIONS,
        payload: options,
    };
}

export function fetchCollectionsStart() {
    return {
        type: FETCH_COLLECTIONS_START,
    };
}

export function fetchCollectionsSuccess(response) {
    return {
        type: FETCH_COLLECTIONS_SUCCESS,
        payload: response.data,
    };
}

export function fetchCollectionsFail(response) {
    return {
        type: FETCH_COLLECTIONS_FAIL,
        // TODO: portare errore nel payload
    };
}

export function updateCollectionSuccess(response) {
    return {
        type: UPDATE_COLLECTION_SUCCESS,
        payload: response.data,
    };
}

// export function updateCollectionFail(response) {
//     return {
//         type: UPDATE_COLLECTION_FAIL,
//         payload: response
//     };
// }

export function deleteCollection(id) {
    return {
        type: DELETE_COLLECTION,
        payload: id,
    };
}

export function deleteCollectionSuccess(id) {
    return {
        type: DELETE_COLLECTION_SUCCESS,
        payload: id,
    };
}

export function changePage(page) {
    return {
        type: CHANGE_COLLECTIONS_PAGE,
        payload: page,
    };
}

export function sortCollectionsBy(attr) {
    return {
        type: SORT_COLLECTIONS_BY,
        payload: attr,
    };
}

export function toggleEngageCollection(id) {
    return {
        type: TOGGLE_ENGAGE_COLLECTION,
        payload: id,
    };
}

export function toggleShownCollection(id) {
    return {
        type: TOGGLE_SHOWN_COLLECTION,
        payload: id,
    };
}

export function setShownCollection(id) {
    return {
        type: SET_SHOWN_COLLECTION,
        payload: id,
    };
}

export function resetShownCollection() {
    return {
        type: RESET_SHOWN_COLLECTION,
    };
}

export function addSelectedItemsToCollection(id) {
    return {
        type: ADD_SELECTED_ITEMS_TO_COLLECTION,
        payload: id,
    };
}

export function toggleItemFromCollection(collectionId, itemId) {
    return {
        type: TOGGLE_ITEM_FROM_COLLECTION,
        payload: {
            collectionId,
            itemId,
        },
    };
}

export function dupeCollection(id) {
    return {
        type: DUPE_COLLECTION,
        payload: id,
    };
}

export function setItemsCollections(response) {
    return {
        type: SET_ITEMS_COLLECTIONS,
        payload: response,
    };
}

export function resetItemsCollections() {
    return {
        type: RESET_ITEMS_COLLECTIONS,
    };
}

export function addAllItemsToCollection() {
    return {
        type: ADD_ALL_ITEMS_TO_COLLECTION,
    };
}

export function removePagedItemsFromCollection() {
    return {
        type: REMOVE_PAGED_ITEMS_FROM_COLLECTION,
    };
}

export function updateCollectionItemsNumber(collectionId, number) {
    return {
        type: UPDATE_COLLECTION_ITEMS_NUMBER,
        payload: {
            collectionId,
            number,
        },
    };
}

export function updateCollectionFilter(key, value) {
    return {
        type: UPDATE_COLLECTION_FILTER,
        payload: {
            key,
            value,
        },
    };
}

export function setLastSelectedCollection(id) {
    return {
        type: SET_LAST_SELECTED_COLLECTION,
        payload: id,
    };
}

export function setCollectionViewActive(active) {
    return {
        type: SET_COLLECTION_VIEW_ACTIVE,
        payload: active,
    };
}

export const actions = {
    fetchCollections,
    fetchCollectionsStart,
    fetchCollectionsSuccess,
    fetchCollectionsFail,
    updateCollectionSuccess,
    // updateCollectionFail,
    deleteCollection,
    deleteCollectionSuccess,
    changePage,
    sortCollectionsBy,
    toggleEngageCollection,
    toggleShownCollection,
    setShownCollection,
    resetShownCollection,
    addSelectedItemsToCollection,
    toggleItemFromCollection,
    addAllItemsToCollection,
    removePagedItemsFromCollection,
    dupeCollection,
    setItemsCollections,
    resetItemsCollections,
    updateCollectionItemsNumber,
    updateCollectionFilter,
    setLastSelectedCollection,
    setCollectionViewActive,
};

export const actionTypes = {
    FETCH_COLLECTIONS,
    FETCH_COLLECTIONS_START,
    FETCH_COLLECTIONS_SUCCESS,
    FETCH_COLLECTIONS_FAIL,
    // UPDATE_COLLECTION,
    UPDATE_COLLECTION_SUCCESS,
    // UPDATE_COLLECTION_FAIL,
    DELETE_COLLECTION,
    DELETE_COLLECTION_SUCCESS,
    CHANGE_COLLECTIONS_PAGE,
    SORT_COLLECTIONS_BY,
    TOGGLE_ENGAGE_COLLECTION,
    TOGGLE_SHOWN_COLLECTION,
    SET_SHOWN_COLLECTION,
    RESET_SHOWN_COLLECTION,
    ADD_SELECTED_ITEMS_TO_COLLECTION,
    TOGGLE_ITEM_FROM_COLLECTION,
    ADD_ALL_ITEMS_TO_COLLECTION,
    REMOVE_PAGED_ITEMS_FROM_COLLECTION,
    DUPE_COLLECTION,
    SET_ITEMS_COLLECTIONS,
    RESET_ITEMS_COLLECTIONS,
    UPDATE_COLLECTION_ITEMS_NUMBER,
    UPDATE_COLLECTION_FILTER,
    SET_LAST_SELECTED_COLLECTION,
    SET_COLLECTION_VIEW_ACTIVE,
};
