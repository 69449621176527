import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default class SortableTableHeader extends Component {
    renderDecorators() {
        const { attr, sorting } = this.props;

        if (attr !== sorting.sortBy) {
            return null;
        }

        if (sorting.sortDirection === 'asc') {
            return <i className="icon icon-arrow-up" />;
        }

        return <i className="icon icon-arrow-down" />;
    }

    render() {
        const {
            disabled,
            onClick,
            attr,
            headerClass,
            headerStyle
        } = this.props;

        return (
            <th
                className={cx(
                    'table-header-sortable',
                    { 'c-hand': disabled === false },
                    headerClass
                )}
                style={headerStyle}
                onClick={() => (disabled ? {} : onClick(attr))}
            >
                {this.props.children} {this.renderDecorators()}
            </th>
        );
    }
}

SortableTableHeader.propTypes = {
    attr: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    headerClass: PropTypes.string,
    headerStyle: PropTypes.object,
    onClick: PropTypes.func,
    sorting: PropTypes.object
};

SortableTableHeader.defaultProps = {
    disabled: false,
    headerClass: '',
    headerStyle: {}
};
