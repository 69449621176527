import React from 'react';
import cx from 'classnames';

export default function TableButton({ children, tooltip, active = false }) {
    return (
        <div
            className={cx('table-button tooltip', {
                'table-button-primary': active
            })}
            data-tooltip={tooltip}
        >
            {React.cloneElement(children, { size: '1.5em' })}
        </div>
    );
}
