import React, { Fragment } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar';
import useResizeAware from 'react-resize-aware';
import clsx from 'classnames';

import Header from './Header';
import UsersList from '../containers/UsersList';
import UserGroupsList from './UserGroupsList';
import DataPool from './DataPool';
import UserProfile from './UserProfile';
import ErrorPage from './ErrorPage';
import ProcessesList from './ProcessesList';
import CustomizationPanel from './CustomizationPanel';
import CustomizeTablePanel from './CustomizeTablePanel';
import ExportsPanel from './ExportsPanel';
import GlobalScrollToTopButton from './utils/GlobalScrollToTopButton';
import CollectionsList from '../containers/CollectionsList';

const Grid = () => {
    const [resizeListener, sizes] = useResizeAware();

    const location = useLocation();

    return (
        <Fragment>
            <LoadingBar scope="itemsFetch" style={{ zIndex: 4, backgroundColor: '#FFC107' }} />
            <Header />
            <div
                className={clsx('flex-grow', {
                    'mt-[4.5rem] lg:mt-11': location.pathname === '/app',
                    'mt-11': location.pathname !== '/app',
                })}
            >
                {resizeListener}
                <Switch>
                    <Route
                        path="/app"
                        exact={true}
                        render={() => <DataPool width={sizes.width} />}
                    />
                    <Route path="/app/users" component={UsersList} exact={true} />
                    <Route path="/app/userGroups" component={UserGroupsList} />
                    <Route path="/app/profile" component={UserProfile} />
                    <Route path="/app/processes" component={ProcessesList} />
                    <Route path="/app/customize" component={CustomizationPanel} />
                    <Route path="/app/customize-table" component={CustomizeTablePanel} />
                    <Route path="/app/lists" component={CollectionsList} />
                    <Route path="/app/exports" component={ExportsPanel} />
                    <Route component={ErrorPage} />
                </Switch>
                <div style={{ position: 'fixed', bottom: '10px', right: '10px' }}>
                    <GlobalScrollToTopButton />
                </div>
            </div>
        </Fragment>
    );
};

export default Grid;
