import transform from 'lodash/transform';
import sortBy from 'lodash/sortBy';

import { getOptionLocaleFromCode } from './intl-helpers';
import {
    getProvince,
    getProvinceUtenti,
    getTitoli,
    getAttivita,
    getAttivitaUtenti,
    getLocales,
    getUserGroups
} from './api';

// TODO: DRY
export function getProvinceOptions() {
    return new Promise(resolve => {
        getProvince().then(res => {
            const options = transform(
                res.data,
                (result, value, key) => {
                    result.push({
                        value: key,
                        label: `${value}`
                    });

                    return result;
                },
                []
            );

            resolve(sortBy(options, 'label'));
        });
    });
}

export function getProvinceUtentiOptions(filters = {}) {
    return new Promise(resolve => {
        getProvinceUtenti(filters).then(res => {
            const options = transform(
                res.data,
                (result, value, key) => {
                    result.push({
                        value: key,
                        label: `${value}`
                    });

                    return result;
                },
                []
            );

            resolve(sortBy(options, 'label'));
        });
    });
}

export function getTitoliOptions() {
    return new Promise(resolve => {
        getTitoli().then(res => {
            const options = res.data.map(value => {
                return {
                    value,
                    label: value
                };
            });

            resolve(sortBy(options, 'label'));
        });
    });
}

export function getAttivitaOptions() {
    return new Promise(resolve => {
        getAttivita().then(res => {
            const options = res.data.map(value => {
                return {
                    value,
                    label: value
                };
            });

            resolve(sortBy(options, 'label'));
        });
    });
}

export function getAttivitaUtentiOptions(filters = {}) {
    return new Promise(resolve => {
        getAttivitaUtenti(filters).then(res => {
            const options = res.data.map(value => {
                return {
                    value,
                    label: value
                };
            });

            resolve(sortBy(options, 'label'));
        });
    });
}

export function getLanguageOptions() {
    return new Promise(resolve => {
        getLocales().then(res => {
            const options = res.data.map(value => {
                return getOptionLocaleFromCode(value);
            });

            resolve(sortBy(options, 'label'));
        });
    });
}

export function getGroupOptions() {
    return new Promise(resolve => {
        getUserGroups().then(res => {
            const options = res.data.data.map(group => {
                return {
                    value: group.id,
                    label: group.label
                };
            });

            resolve(sortBy(options, 'label'));
        });
    });
}

export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
