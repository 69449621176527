import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class Modal extends Component {
    render() {
        if (this.props.isOpen) {
            return (
                <div
                    className={cx('modal', 'active', this.props.modalRootClass)}
                >
                    <div className="modal-overlay" />
                    <div className="modal-container">{this.props.children}</div>
                </div>
            );
        }

        return null;
    }
}

Modal.propTypes = {
    isOpen: PropTypes.bool,
    modalRootClass: PropTypes.string
};

Modal.defaultProps = {
    isOpen: false,
    modalRootClass: ''
};

export default Modal;
