import React from 'react';
import { includes, xor } from 'lodash';
import clsx from 'classnames';

import DropdownMultiple from '../utils/DropdownMultiple';
import FieldHoc from './FieldHoc';

export function SelectMultiple({
    value,
    onChange,
    label,
    labelClass,
    inputClassName,
    inputWrapperClass = '',
    valueKey = 'value',
    options,
}) {
    // FIXME: traduzioni
    // FIXME: passare locale dinamicamente

    const [selectedItemsId, setSelectedItemsId] = React.useState(value);

    // console.log(data);

    function onSelect(selectedItem) {
        const newIds = xor(selectedItemsId, [selectedItem[valueKey]]);

        setSelectedItemsId(newIds);
        onChange(newIds);
    }

    function onRemove(itemId) {
        const newIds = xor(selectedItemsId, [itemId]);

        setSelectedItemsId(newIds);
        onChange(newIds);
    }

    const items = React.useMemo(() => {
        if (!options) {
            return [];
        }

        return options;
    }, [options]);

    const selectedItems = items.filter((item) => {
        return includes(selectedItemsId, item[valueKey]);
    });

    // console.log(selectedItemsId);

    return (
        <div className={inputWrapperClass}>
            <DropdownMultiple
                placeholder={label}
                itemToString={(item) => {
                    if (item && item.label) {
                        return `${item.label.it_IT}`;
                    }

                    return '-';
                }}
                items={items}
                itemValueKey={valueKey}
                selectedItem={selectedItems}
                onLoad={() => {}}
                onChange={onSelect}
                onClear={() => {
                    setSelectedItemsId([]);
                    onChange([]);
                }}
                onRemove={onSelect}
                inputClassName={inputClassName}
            />
            <div>
                {selectedItems.map((item) => {
                    return (
                        <span
                            className="label label-secondary"
                            key={item[valueKey]}
                            style={{ marginRight: '5px', marginTop: '2px' }}
                        >
                            {item.label.it_IT}
                            <i
                                onClick={() => onRemove(item[valueKey])}
                                className="icon icon-cross c-hand"
                            ></i>
                        </span>
                    );
                })}
            </div>
        </div>
    );
}

export function SelectMultipleFieldComponent({
    input,
    meta,
    placeholder,
    className,
    options,
    ...rest
}) {
    return (
        <SelectMultiple
            inputClassName={clsx('form-input input-lg')}
            options={options}
            {...input}
            {...rest}
        />
    );
}

export const SelectMultipleField = FieldHoc(SelectMultipleFieldComponent);
