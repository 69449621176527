import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { batchedSubscribe } from 'redux-batched-subscribe';
// import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import createSagaMiddleware, { END } from 'redux-saga';
import persistState from 'redux-localstorage';
// import { fromJS } from 'immutable';
// import pick from 'lodash/pick';
// import Immutable from 'seamless-immutable';

import reducer from '../reducers';
import { deserializePersistedState } from '../utils/serializers';
// import { UserRecord, DEFAULT_STATE } from '../reducers/userReducer';
// import { AppRecord } from '../reducers/appReducer';
// import { getShortCodeFromLocale } from '../intl-helpers';

const sagaMiddleware = createSagaMiddleware();

const enhancer = compose(
    applyMiddleware(
        thunkMiddleware,
        sagaMiddleware
        // unauthorizedMiddleware,
    ),
    persistState(['user', 'app'], {
        key: 'datapool',
        deserialize: deserializePersistedState
    })
    // batchedSubscribe(batchedUpdates)
);

export default function configureStore(preloadedState = {}) {
    const store = createStore(reducer, preloadedState, enhancer);

    store.runSaga = sagaMiddleware.run;
    store.close = () => store.dispatch(END);

    return store;
}
