import React from 'react';
import { Trans } from '@lingui/react';

export default function AppLoader() {
    return (
        <div className="splash-loader">
            <div className="text-center text-primary">
                <h2>
                    <Trans id="loading" />
                    ...
                </h2>
            </div>
            <div className="loading-wrapper loading loading-lg" />
        </div>
    );
}
