import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { intersection, isNaN } from 'lodash';
// import { delay } from 'redux-saga';

import { actionTypes, actions, setFixedBrand } from '../actions/appActions';
import {
    setFilterOptions,
    enableFilters,
    setPriceRange,
    // changeBrandFilterValue
} from '../actions/filtersActions';
import { selectors as filtersSelectors } from '../reducers/filtersReducer';
import { selectors as userSelectors } from '../reducers/userReducer';
import { setProductClassOptions } from '../actions/productClassesActions';
import { setTree, initProductTreeRoots } from '../actions/sectorsActions';
import { fetchCollections } from '../actions/collectionsActions';
import { removeSplashScreenLoader } from '../helpers';
// import { setChosenCategories } from '../actions/itemDataCategoriesActions';
import {
    fetchBrandFilter,
    fetchClassificationTree,
    fetchEtimClassFilter,
    // getProductInfoMap,
    getAllProductProperties,
    // fetchAnagrafica,
    fetchProductTreeRootsFilter,
    getUserGroups,
    fetchPriceRangeFilter,
    fetchPreferences,
} from '../api';
import * as constants from '../constants';
import { fetchItems } from '../actions/itemsActions';
import { brandDependentFilters } from './filtersSaga';
import { selectors } from '../reducers/appReducer';

export function* bootstrapApp() {
    try {
        const user = yield select(userSelectors.getUser);

        yield put(actions.bootstrapAppStart());

        const productTreeRootsRes = yield call(fetchProductTreeRootsFilter);

        yield put(initProductTreeRoots(productTreeRootsRes.data));

        const apiPayload = yield select(filtersSelectors.getApiPayload);

        const calls = [
            call(fetchBrandFilter, apiPayload),
            call(fetchClassificationTree, apiPayload),
            call(fetchEtimClassFilter, apiPayload),
            call(fetchPriceRangeFilter, apiPayload),
            // call(getProductInfoMap),
            call(getAllProductProperties),
            // call(fetchAnagrafica),
        ];

        if (user.ruolo === 'admin') {
            calls.push(call(getUserGroups));
        }

        const [
            brandsRes,
            treeRes,
            etimClassRes,
            priceRangeRes,
            // productInfoMapRes,
            productPropertiesRes,
            // anagraficaRes,
            userGroupsRes,
        ] = yield all(calls);

        yield put(setFilterOptions(constants.BRAND_FILTER, brandsRes.data));
        yield put(setPriceRange(priceRangeRes.data));
        // yield put(actions.setProductInfoMap(productInfoMapRes.data));

        yield put(actions.setProductProperties(productPropertiesRes.data));

        yield put(setProductClassOptions(etimClassRes.data));
        yield put(setTree(treeRes.data));

        if (user.ruolo === 'admin') {
            yield put(actions.setUserGroups(userGroupsRes.data));
        } else {
            // Se non sono admin verifico che le colonne selezionate per la tabella personalizzata
            // siano tra quelle per cui il mio gruppo ha il permesso di visualizzazione
            const currentTableColumns = yield select(selectors.getTableColumns);

            if (currentTableColumns && currentTableColumns.length > 0) {
                const validProps = productPropertiesRes.data.map((p) => p.code);

                yield put(actions.setTableColumns(intersection(currentTableColumns, validProps)));
            }
        }

        yield put(fetchCollections());

        if (brandsRes.data.length === 1) {
            // if (brandsRes.data.length > 1) {
            const brand = brandsRes.data[0];

            yield put(
                setFixedBrand({
                    label: brand.nome,
                    value: brand.id,
                    code: brand.code,
                })
            );

            yield put(fetchItems({ bootstrap: true }));

            yield put(enableFilters(brandDependentFilters));
        } else {
            yield put(actions.bootstrapAppSuccess());
        }
    } catch (err) {
        // TODO: gestire messaggio di errore
        yield put(actions.bootstrapAppFail('Unable to contact remote server'));
        console.error(err);
    }
}

function* handleLoadPreferences() {
    try {
        const preferencesRes = yield call(fetchPreferences);

        if (preferencesRes.data) {
            const appName = preferencesRes.data.filter(
                (preference) => preference.name === 'appName'
            );

            if (appName.length > 0 && appName[0].value !== '') {
                yield put(actions.setAppName(appName[0].value));
            }

            const appColor = preferencesRes.data.filter(
                (preference) => preference.name === 'appColor'
            );

            if (appColor.length > 0) {
                window.document.body.style.setProperty('--color-primary', appColor[0].value);
            }

            const alternateRowColor = preferencesRes.data.filter(
                (preference) => preference.name === 'alternateRowColor'
            );

            if (alternateRowColor.length > 0) {
                window.document.body.style.setProperty(
                    '--color-alternate-row',
                    alternateRowColor[0].value
                );
            }

            const priceDecimalPrecision = preferencesRes.data.filter(
                (preference) => preference.name === 'priceDecimalPrecision'
            );

            if (priceDecimalPrecision.length > 0 && !isNaN(parseInt(priceDecimalPrecision[0].value))) {
                yield put(
                    actions.setAppParameter(
                        'priceDecimalPrecision',
                        parseInt(priceDecimalPrecision[0].value)
                    )
                );
            }
        }
    } catch (err) {
        console.error(err);
    }
}

function hideLoadingSplashScreen() {
    removeSplashScreenLoader();
}

export default [
    takeEvery(actionTypes.BOOTSTRAP_APP, bootstrapApp),
    takeEvery(actionTypes.HIDE_LOADING_SPLASH_SCREEN, hideLoadingSplashScreen),
    takeEvery(actionTypes.LOAD_PREFERENCES, handleLoadPreferences),
];
