import React, { Component } from 'react';
import { connect } from 'react-redux';

import HeaderSearch from '../components/HeaderSearch';
import { selectors } from '../reducers/filtersReducer';
import * as constants from '../constants';

export class HeaderSearchContainer extends Component {
    render() {
        return <HeaderSearch {...this.props} />;
    }
}

function mapStateToProps(state) {
    return {
        fullTextFilter: selectors.getFilter(state, constants.FULL_TEXT_FILTER)
    };
}

export default connect(mapStateToProps)(HeaderSearchContainer);
