import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DropdownList from '../utils/DropdownList';
import { getGroupOptions } from '../../form-helpers';

export default class SelectGroup extends Component {
    state = {
        items: [],
        isFetching: true,
    };

    componentDidMount() {
        this.fetchOptions();
    }

    componentDidUpdate(prevProps) {
        if (this.props.filter !== prevProps.filter) {
            this.fetchOptions();
        }
    }

    fetchOptions() {
        const { filter } = this.props;

        getGroupOptions({ q: filter }).then((options) => {
            this.setState({
                items: options,
                isFetching: false,
            });
        });
    }

    render() {
        const { items, isFetching } = this.state;

        return (
            <DropdownList
                useVirtualList={false}
                items={items}
                isLoading={isFetching}
                {...this.props}
            />
        );
    }
}

SelectGroup.propTypes = {
    filter: PropTypes.string,
};
