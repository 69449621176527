import React, { useState, useEffect, useCallback } from 'react';
// import { animateScroll as scroll } from 'react-scroll';
import cx from 'classnames';
import throttle from 'lodash/throttle';

export default function GlobalScrollToTopButton({ container }) {
    const [visible, setVisible] = useState(false);

    const toggleVisible = useCallback(() => {
        const scrolled = container
            ? container.current.scrollTop
            : document.documentElement.scrollTop;
        // console.log('SCROLLTOP', scrolled);

        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    }, [container]);

    const scrollToTop = useCallback(() => {
        // console.log('SCROLL TO TOP');
        if (container) {
            container.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [container]);

    useEffect(() => {
        if (container) {
            container.current.addEventListener('scroll', throttle(toggleVisible, 100));
        } else {
            window.addEventListener('scroll', throttle(toggleVisible, 100));
        }

        return () => {
            if (container && container.current) {
                // eslint-disable-next-line
                container.current.removeEventListener('scroll', throttle(toggleVisible, 100));
            } else {
                window.removeEventListener('scroll', throttle(toggleVisible, 100));
            }
        };
    }, [container, toggleVisible]);

    return (
        <button
            className={cx('btn btn-action circle btn-primary')}
            style={{ display: visible ? 'inline' : 'none' }}
            onClick={scrollToTop}
        >
            <i className="icon icon-arrow-up" />
        </button>
    );
}
