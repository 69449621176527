import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, i18nMark, withI18n } from '@lingui/react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

import MatchWhenGuest from './components/routing/MatchWhenGuest';
import InputField from './components/form/InputField';
import SubmitButton from './components/form/SubmitButton';
import AppLogo from './components/utils/AppLogo';
import { login } from './api';
import { authSuccessfull } from './actions/userActions';
import { validateEmail } from './form-helpers';

function validate(values) {
    const errors = {};

    if (!values.email) {
        errors.email = i18nMark('validation:error:email:mandatory');
    } else if (!validateEmail(values.email)) {
        errors.email = i18nMark('validation:error:email:invalid');
    }

    if (!values.password) {
        errors.password = i18nMark('validation:error:password');
    }

    return errors;
}

export class Login extends Component {
    componentDidMount() {
        setTimeout(() => {
            this.form.elements[0].focus();
        }, 0);
    }

    onSubmit = async (data) => {
        const { i18n } = this.props;

        // TODO: spostare tutta questa logica in una saga?
        // console.warn(data);
        try {
            const res = await login(data);
            // console.warn(res);
            this.props.dispatch(authSuccessfull(res.data));
        } catch (err) {
            // console.error(err);
            let errorMessage = i18n._('error:unexpected');

            if (err.response) {
                // console.log(err.response.data);
                // console.log(err.response.status);
                if (err.response.status === 401 || err.response.status === 412) {
                    errorMessage = i18n._('error:login:invalid');
                } else if (err.response.status === 403) {
                    errorMessage = i18n._('error:login:user:disabled');
                }
            } else if (err.request) {
                errorMessage = i18n._('error:server:generic');
            }

            return {
                [FORM_ERROR]: errorMessage,
            };
        }
    };

    render() {
        return (
            <div className="bg-primary login-wrapper">
                <div className="container md:grid md:grid-cols-8 lg:grid-cols-4">
                    <div className="md:col-span-6 md:col-start-2 lg:col-span-2 lg:col-start-2">
                        <Form
                            onSubmit={this.onSubmit}
                            validate={validate}
                            render={({
                                handleSubmit,
                                pristine,
                                // hasValidationErrors,
                                submitError,
                                submitting,
                                // ...rest
                            }) => {
                                // console.warn(rest);

                                return (
                                    <form
                                        ref={(form) => (this.form = form)}
                                        onSubmit={handleSubmit}
                                        className="form-spaced pb-4"
                                    >
                                        <div className="mb-3 flex">
                                            <div className="inline-block m-auto">
                                                <AppLogo />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <Field
                                                name="email"
                                                component={InputField}
                                                placeholder={i18nMark('email')}
                                                className="form-input input-lg"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <Field
                                                name="password"
                                                component={InputField}
                                                placeholder={i18nMark('password')}
                                                className="form-input input-lg"
                                                inputType="password"
                                            />
                                        </div>
                                        <SubmitButton submitting={submitting} disabled={pristine}>
                                            <Trans id="enter" />
                                        </SubmitButton>
                                        {submitError && (
                                            <div className="bg-error mt-2 p-2 text-center">
                                                {submitError}
                                            </div>
                                        )}
                                        <div
                                            className="text-gray text-center"
                                            style={{ marginTop: '10px' }}
                                        >
                                            <Trans id="browser:suggested" />{' '}
                                            <a
                                                href="https://www.google.com/intl/it_ALL/chrome/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                Google Chrome
                                            </a>
                                        </div>
                                    </form>
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="container grid grid-cols-8 mt-4">
                    <div className="md:col-start-2 lg:col-start-3 col-span-4 text-left lg:col-span-2">
                        <Link to="/password-lost" style={{ color: '#fff' }}>
                            <Trans id="Password lost?" />
                        </Link>
                    </div>
                    <div className="col-span-4 md:col-span-2 text-right">
                        <span className="">
                            <Link to="/signup" style={{ color: '#fff', fontWeight: 700 }}>
                                <Trans id="register" />
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

const LoginIntl = withI18n()(Login);

export default connect()(MatchWhenGuest(LoginIntl));
