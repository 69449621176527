import React from 'react';
import PropTypes from 'prop-types';
import { withI18n } from '@lingui/react';
import cx from 'classnames';

import FieldHoc from './FieldHoc';

const TextareaField = React.forwardRef(
    ({ input, meta, placeholder, className, i18n, disabled, ...rest }, ref) => {
        // console.warn(ref);
        // console.warn(input);

        return (
            <textarea
                ref={rest.forwardRef}
                {...input}
                disabled={disabled}
                placeholder={i18n._(placeholder) + (rest.mandatory ? '*' : '')}
                className={cx(className, {
                    'is-error': meta.error && meta.touched,
                })}
            />
        );
    }
);

TextareaField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    inputType: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
};

TextareaField.defaultProps = {
    inputType: 'text',
    className: 'form-input',
};

const TextareaFieldWithI18n = withI18n()(TextareaField);

export default FieldHoc(TextareaFieldWithI18n);
