import React from 'react';
import { getAssetURL } from './../../api';

const AppLogo = ({ height = 50, style = {} }) => (
    <img
        src={getAssetURL(`logo_full.png?t=${new Date().getTime()}`)}
        style={{ height, ...style }}
        alt="Datapool logo"
    />
);

export default AppLogo;
