export const AUTH_SUCCESSFULL = 'AUTH_SUCCESSFULL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_USER = 'SET_USER';
export const QUEUE_REFRESH = 'QUEUE_REFRESH';

export function authSuccessfull(payload) {
    return {
        type: AUTH_SUCCESSFULL,
        payload
    };
}

export function setUser(payload) {
    return {
        type: SET_USER,
        payload
    };
}

export function authLogout() {
    return {
        type: AUTH_LOGOUT
    };
}

export function updateUser(payload) {
    return {
        type: UPDATE_USER,
        payload
    };
}

export function queueRefresh() {
    return {
        type: QUEUE_REFRESH
    };
}

export const actionTypes = {
    AUTH_SUCCESSFULL,
    AUTH_LOGOUT,
    SET_USER,
    UPDATE_USER,
    QUEUE_REFRESH
};

export const actions = {
    authSuccessfull,
    authLogout,
    setUser,
    updateUser,
    queueRefresh
};
