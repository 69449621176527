import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

class Paginator extends Component {
    handlePageClick = ({ selected: p }) => {
        this.props.onPageChange(p);
    };

    render() {
        const { currentPage, pages, containerClassName } = this.props;

        if (pages === 0) {
            return null;
        }

        return (
            <ReactPaginate
                previousLabel={<i className="icon icon-arrow-left" />}
                nextLabel={<i className="icon icon-arrow-right" />}
                breakLabel={<span className="c-hand">...</span>}
                forcePage={currentPage}
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={containerClassName}
                previousClassName="page-item c-hand"
                nextClassName="page-item c-hand"
                pageClassName="page-item"
                pageLinkClassName="c-hand"
                activeClassName="active"
            />
        );
    }
}

Paginator.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    containerClassName: PropTypes.string
};

Paginator.defaultProps = {
    containerClassName: 'pagination'
};

export default Paginator;
