import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans, NumberFormat } from '@lingui/react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import cx from 'classnames';
import get from 'lodash/get';

import {
    getIntlNumberFormat,
    getLocaleFromLanguageCode,
    getLocalizedTextWithFallback,
} from '../intl-helpers';
import { getDescrizioneMarcaFromArticolo } from '../helpers';
import { formatKeyValue } from '../formatters';
import { selectors } from '../reducers/appReducer';

// import { UM_LABELS, STATUS_LABELS } from '../constants';

const intl = getIntlNumberFormat();

export class ArticleRecap extends Component {
    getCodiciInternazionali() {
        if (get(this.props.details, 'identificazione.codici_internazionali.value')) {
            return formatKeyValue(this.props.details.identificazione.codici_internazionali.value);
        }

        return '';
    }

    getQtaMinimaAcquistabile() {
        // if (
        //     this.props.details.dettagli_ordine.quantita_minima_acquistabile
        //         .value !== null
        // ) {
        //     return intl.format(
        //         this.props.details.dettagli_ordine.quantita_minima_acquistabile
        //             .value
        //     );
        // }
        if (
            get(this.props.details.dettagli_ordine, 'quantita_minima_acquistabile.value', null) !==
            null
        ) {
            return intl.format(
                this.props.details.dettagli_ordine.quantita_minima_acquistabile.value
            );
        }

        return '';
    }

    getUmImballo() {
        return getLocalizedTextWithFallback(
            get(this.props.details, 'dettagli_ordine.unita_ordine.value.label', {}),
            this.getLocale()
        );

        // return get(
        //     this.props.details,
        //     `dettagli_ordine.unita_contenuta.value.label[${this.getLocale()}]`
        // );
    }

    getQtaConfenzione() {
        if (get(this.props.details, 'dettagli_ordine.unita_per_prezzo.value')) {
            return intl.format(this.props.details.dettagli_ordine.unita_per_prezzo.value);
        }

        return '';
    }

    getStatus() {
        return getLocalizedTextWithFallback(
            get(this.props.details, 'identificazione.stato.value.label', {}),
            this.getLocale()
        );
    }

    getStatusCode() {
        return get(this.props.details, 'identificazione.stato.value.code', null);
    }

    getInizioValiditaPrezzo() {
        return get(this.props.details, 'prezzi[0].data_inizio_validita_prezzo.value');
    }

    getMarcaArticolo() {
        return getDescrizioneMarcaFromArticolo(this.props.details, this.getLocale());
    }

    getDescrizioneArticolo() {
        return getLocalizedTextWithFallback(
            get(this.props.details, 'identificazione.descrizione_articolo.value', {}),
            this.getLocale()
        );

        // return get(
        //     this.props.details,
        //     `identificazione.descrizione_articolo.value.${this.getLocale()}`
        // );
    }

    getUnitaOrdine() {
        return getLocalizedTextWithFallback(
            get(this.props.details, 'dettagli_ordine.unita_ordine.value.label', {}),
            this.getLocale()
        );
        // return get(
        //     this.props.details,
        //     `dettagli_ordine.unita_ordine.value.label.${this.getLocale()}`
        // );
    }

    getPrezzo() {
        let price = get(this.props.details, 'prezzi.0.prezzo_listino.value', null);

        if (this.props.priceDecimalPrecision === 0) {
            price = Math.trunc(price);
        }

        return price;
    }

    getCodiceArticolo() {
        return get(this.props.details, 'identificazione.codice_articolo.value');
    }

    getLocale() {
        return getLocaleFromLanguageCode(this.props.language);
    }

    getPropLabel(key) {
        return get(
            this.props.productProperties,
            `${key}.label[${this.getLocale()}]`,
            ''
        ).toLowerCase();
    }

    render() {
        // const { item /*, details*/ } = this.props;

        const price = this.getPrezzo();

        // console.warn(item);
        // console.log(this.props.details);

        // TODO: item.listino non é attualmente valorizzato, appena lo sarà aggiornare il codice
        // per ottenere il dato dai dettagli

        return (
            <table className="table table-striped table-striped-alt table-hover table-article-recap mb-2">
                <tbody>
                    <tr>
                        <td>{this.getPropLabel('descrizione_marca')}</td>
                        <td>
                            <strong>{this.getMarcaArticolo()}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{this.getPropLabel('codice_articolo')}</td>
                        <td>
                            <strong>{this.getCodiceArticolo()}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{this.getPropLabel('codici_internazionali')}</td>
                        <td>
                            <strong>{this.getCodiciInternazionali()}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{this.getPropLabel('descrizione_articolo')}</td>
                        <td>
                            <strong>{this.getDescrizioneArticolo()}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{this.getPropLabel('prezzo_listino')}</td>
                        <td>
                            {price !== null && (
                                <strong>
                                    <span className="text-error">
                                        €{' '}
                                        <NumberFormat
                                            value={price}
                                            format={{
                                                minimumFractionDigits:
                                                    this.props.priceDecimalPrecision,
                                                maximumFractionDigits:
                                                    this.props.priceDecimalPrecision,
                                            }}
                                        />{' '}
                                    </span>
                                    {this.getInizioValiditaPrezzo() !== null && (
                                        <Fragment>
                                            <Trans id="from" />{' '}
                                            {format(this.getInizioValiditaPrezzo(), 'DD/MM/YYYY')}
                                        </Fragment>
                                    )}
                                </strong>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>{this.getPropLabel('unita_ordine')}</td>
                        <td>
                            <strong>{this.getUnitaOrdine()}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{this.getPropLabel('quantita_minima_acquistabile')}</td>
                        <td>
                            <strong>{this.getQtaMinimaAcquistabile()}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{this.getPropLabel('unita_contenuta')}</td>
                        <td>
                            <strong>{this.getUmImballo()}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{this.getPropLabel('quantita_contenuta')}</td>
                        <td>
                            <strong>{this.getQtaConfenzione()}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{this.getPropLabel('stato')}</td>
                        <td>
                            <strong
                                className={cx({
                                    'blink text-error': this.getStatusCode() === 'IV006650',
                                })}
                            >
                                {this.getStatus()}
                            </strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

ArticleRecap.propTypes = {
    item: PropTypes.object,
    details: PropTypes.object,
    language: PropTypes.string,
    productProperties: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        priceDecimalPrecision: selectors.getPriceDecimalPrecision(state),
    };
}

export default connect(mapStateToProps)(ArticleRecap);
