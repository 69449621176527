import React from 'react';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

export default function ListiniModal({ brand, listini, onClose }) {
    const ordered = sortBy(listini.options, ['extra.codice_listino', 'extra.codice_revisione']);

    // console.log(ordered);

    return (
        <div className="modal active" id="user-details-modal-id">
            <span className="modal-overlay" aria-label="Close" />
            <div className="modal-container" style={{ maxWidth: '1200px' }}>
                <div className="modal-header">
                    <div className="modal-title text-center h5">
                        Listini {get(brand, 'value[0].label.it_IT')}
                        <span
                            className="btn btn-clear float-right"
                            aria-label="Close"
                            onClick={onClose}
                        />
                    </div>
                </div>
                <div className="modal-body" id="listini-list-modal">
                    <table
                        className="table table-striped table-hover table-dense"
                        style={{ marginBottom: '20px' }}
                    >
                        <thead>
                            <tr>
                                <th>Codice listino</th>
                                <th>Codice listino prod.</th>
                                <th>Descrizione listino</th>
                                <th>Data listino</th>
                                <th>Data validità</th>
                                <th>Codice revisione</th>
                                <th>Descrizione revisione</th>
                                <th>Data Validità revisione</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ordered.map((listino) => {
                                return (
                                    <tr key={listino.value}>
                                        <td>{listino.code}</td>
                                        <td>{listino.extra.codice_listino_prod}</td>
                                        <td>{get(listino, 'label.it_IT', '-')}</td>
                                        <td>
                                            {format(
                                                parse(listino.extra.data_listino),
                                                'DD/MM/YYYY'
                                            )}
                                        </td>
                                        <td>
                                            {format(
                                                parse(listino.extra.data_validita),
                                                'DD/MM/YYYY'
                                            )}
                                        </td>
                                        <td>
                                            {listino.extra.codice_revisione !== '00'
                                                ? listino.extra.codice_revisione
                                                : ''}
                                        </td>
                                        <td>
                                            {listino.extra.codice_revisione !== '00'
                                                ? get(
                                                      listino,
                                                      'extra.descrizione_revisione.it_IT',
                                                      '-'
                                                  )
                                                : ''}
                                        </td>
                                        <td>
                                            {listino.extra.codice_revisione !== '00'
                                                ? format(
                                                      parse(listino.extra.data_validita_rev),
                                                      'DD/MM/YYYY'
                                                  )
                                                : ''}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
