import { fromJS } from 'immutable';
import pick from 'lodash/pick';

import { AppRecord } from '../reducers/appReducer';
import { DEFAULT_STATE, UserRecord } from '../reducers/userReducer';
import { getShortCodeFromLocale } from '../intl-helpers';

export function deserializePersistedState(data) {
    let parsed = JSON.parse(data);

    if (parsed === null) {
        return {};
    }

    const now = new Date().getTime() / 1000;

    // let language = parsed.app.language;

    const appParsed = pick(parsed.app, ['language', 'defaultTableView', 'tableColumns']);
    appParsed.tableColumns = fromJS(appParsed.tableColumns);

    if (now > parsed.user.token_expire) {
        parsed.user = DEFAULT_STATE;
    } else {
        // se esiste un utente valido recupero la lingua dal suo locale
        appParsed.language = getShortCodeFromLocale(parsed.user.locale);
    }

    return {
        user: UserRecord(parsed.user),
        app: AppRecord({
            ...appParsed
        })
    };
}
