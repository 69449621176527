import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Trans, i18nMark } from '@lingui/react';
import { Form, Field } from 'react-final-form';
import { FaListAlt, FaTrash } from 'react-icons/fa';
import { Portal } from 'react-portal';
import find from 'lodash/find';

import Tooltip from './utils/Tooltip';
import DropdownList from './utils/DropdownList';
import InputField from './form/InputField';
import { selectors } from '../reducers/collectionsReducer';
import { selectors as itemsSelectors } from '../reducers/itemsReducer';
import { selectors as userSelectors } from '../reducers/userReducer';
// import { addSelectedItemsToCollection } from '../actions/collectionsActions';
import { addItemsToCollection, createCollection, removeItemsFromCollection } from '../api';
import { getMessageFromI18nCatalog, getShortCodeFromLocale } from '../intl-helpers';
import { fetchItems, resetSelectedItems, toggleAllSelectedItems } from '../actions/itemsActions';
import { fetchCollections, setLastSelectedCollection } from '../actions/collectionsActions';

const validate = (data) => {
    let errors = {};

    if (!data.descrizione) {
        errors.descrizione = i18nMark('validation:error:mandatory:field');
    }

    return errors;
};

function AddToCollectionBtnComponent({
    itemId,
    selectedItems,
    collectionEngagedId,
    user,
    dispatch,
}) {
    const [showModal, setShowModal] = useState(false);

    const enableAddItemsModal = itemId || selectedItems.count() > 0;

    return (
        <>
            <span
                className="cursor-pointer text-primary"
                style={{ position: 'relative', top: '-1px' }}
                onClick={async (e) => {
                    e.stopPropagation();

                    if (collectionEngagedId) {
                        try {
                            await removeItemsFromCollection(
                                user.id,
                                collectionEngagedId,
                                itemId ? itemId : selectedItems.toJS()
                            );

                            toast('Articoli rimossi con successo!', {
                                position: 'bottom-right',
                                type: toast.TYPE.SUCCESS,
                            });

                            setTimeout(() => {
                                dispatch(fetchItems());
                            }, 500);
                            dispatch(fetchCollections());
                            dispatch(resetSelectedItems());
                        } catch (e) {
                            const language = getShortCodeFromLocale(user.locale);
                            toast(getMessageFromI18nCatalog(language, 'error:unexpected'), {
                                position: 'bottom-right',
                                type: toast.TYPE.ERROR,
                            });
                        }
                    } else {
                        enableAddItemsModal
                            ? setShowModal(true)
                            : alert('Selezionare almeno un articolo!');
                    }
                }}
            >
                {collectionEngagedId ? (
                    <Tooltip
                        content={itemId ? 'Rimuovi articolo da lista' : 'Rimuovi articoli da lista'}
                        position="top"
                    >
                        <FaTrash size={'22px'} />
                    </Tooltip>
                ) : (
                    <Tooltip
                        content={itemId ? 'Aggiungi articolo a lista' : 'Aggiungi articoli a lista'}
                        position="top"
                    >
                        <FaListAlt size={'22px'} />
                    </Tooltip>
                )}
            </span>
            {showModal && (
                <Portal>
                    <AddToCollectionModal onClose={() => setShowModal(false)} itemId={itemId} />
                </Portal>
            )}
        </>
    );
}

function mapStateToPropsBtn(state) {
    return {
        selectedItems: itemsSelectors.getSelectedItems(state),
        collectionEngagedId: selectors.getEngagedCollection(state),
        user: userSelectors.getUser(state),
    };
}

export const AddToCollectionBtn = connect(mapStateToPropsBtn)(AddToCollectionBtnComponent);

function AddToCollectionModalComponent({
    onClose,
    collections,
    user,
    selectedItems,
    itemId,
    dispatch,
    lastSelectedCollection,
}) {
    // console.log(selectedItems);

    const [collection, setCollection] = useState(null);

    useEffect(() => {
        if (lastSelectedCollection) {
            const lastCollection = find(collections.toJS(), { id: lastSelectedCollection });

            if (lastCollection) {
                setCollection({ value: lastSelectedCollection, label: lastCollection.descrizione });
            }
        }
    }, [lastSelectedCollection]);

    async function addToCollection(collectionId) {
        try {
            await addItemsToCollection(
                user.id,
                collectionId,
                itemId ? itemId : selectedItems.toJS()
            );

            toast('Articoli aggiunti con successo!', {
                position: 'bottom-right',
                type: toast.TYPE.SUCCESS,
            });

            dispatch(setLastSelectedCollection(collectionId));
            dispatch(resetSelectedItems());
            dispatch(toggleAllSelectedItems());
            onClose();
        } catch (e) {
            const language = getShortCodeFromLocale(user.locale);
            toast(getMessageFromI18nCatalog(language, 'error:unexpected'), {
                position: 'bottom-right',
                type: toast.TYPE.ERROR,
            });
        }
    }

    return (
        <div className="modal active" id="user-details-modal-id">
            <span className="modal-overlay" aria-label="Close" />
            <div className="modal-container" style={{ maxWidth: '800px' }}>
                <div className="modal-header">
                    <div className="modal-title text-center h5 text-primary">
                        <Trans id="Aggiungi a lista" />
                        <span
                            className="btn btn-clear float-right"
                            aria-label="Close"
                            onClick={onClose}
                        />
                    </div>
                </div>
                <div className="modal-body" id="user-details-modal">
                    <div className="content">
                        <div className="container">
                            <div className="columns">
                                <div className="column col-12 h-52">
                                    <h6 className="text-primary">Seleziona la lista</h6>
                                    <DropdownList
                                        useVirtualList={false}
                                        onChange={(c) => setCollection(c)}
                                        filterWhenValue={false}
                                        selectedItem={collection}
                                        items={collections.toJS().map((c) => {
                                            return {
                                                label: c.descrizione,
                                                value: c.id,
                                            };
                                        })}
                                    />
                                    <div className="text-center mt-4">
                                        <button
                                            className="btn btn-primary"
                                            onClick={async () => {
                                                try {
                                                    await addItemsToCollection(
                                                        user.id,
                                                        collection.value,
                                                        itemId ? itemId : selectedItems.toJS()
                                                    );

                                                    toast('Articoli aggiunti con successo!', {
                                                        position: 'bottom-right',
                                                        type: toast.TYPE.SUCCESS,
                                                    });

                                                    dispatch(
                                                        setLastSelectedCollection(collection.value)
                                                    );
                                                    dispatch(resetSelectedItems());
                                                    onClose();
                                                } catch (e) {
                                                    const language = getShortCodeFromLocale(
                                                        user.locale
                                                    );
                                                    toast(
                                                        getMessageFromI18nCatalog(
                                                            language,
                                                            'error:unexpected'
                                                        ),
                                                        {
                                                            position: 'bottom-right',
                                                            type: toast.TYPE.ERROR,
                                                        }
                                                    );
                                                }
                                                // dispatch(addSelectedItemsToCollection(collection.value))
                                            }}
                                        >
                                            Aggiungi
                                        </button>
                                    </div>
                                    <h6 className="text-primary">Crea nuova lista</h6>
                                    <Form
                                        onSubmit={async (data) => {
                                            const res = await createCollection(user.id, data);

                                            if (res.status === 200) {
                                                const collectionId = res.data.id;

                                                addToCollection(collectionId);
                                            }
                                        }}
                                        validate={validate}
                                        // initialValues={this.props.collection.toJS()}
                                        render={({
                                            handleSubmit,
                                            pristine,
                                            hasValidationErrors,
                                            submitError,
                                            submitting,
                                            errors,
                                            touched,
                                            submitSucceeded,
                                            form,
                                            ...rest
                                        }) => {
                                            return (
                                                <form
                                                    onSubmit={handleSubmit}
                                                    className="form-horizontal"
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="">
                                                        <Field
                                                            name="descrizione"
                                                            component={InputField}
                                                            placeholder={i18nMark('description')}
                                                            className="form-input input-md my-2"
                                                            // layout="horizontal"
                                                            mandatory={true}
                                                            onlyInput={true}
                                                        />
                                                    </div>
                                                    <div className="text-center pt-3">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() => {}}
                                                            disabled={submitting}
                                                        >
                                                            <Trans id="confirm:create" />
                                                        </button>
                                                    </div>
                                                </form>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        collections: selectors.getDisplayItems(state),
        collectionEngagedId: selectors.getEngagedCollection(state),
        collectionShown: selectors.getShownCollection(state),
        selectedItems: itemsSelectors.getSelectedItems(state),
        lastSelectedCollection: selectors.getLastSelectedCollection(state),
        user: userSelectors.getUser(state),
    };
}

const AddToCollectionModal = connect(mapStateToProps)(AddToCollectionModalComponent);

export default AddToCollectionModal;
