import forOwn from 'lodash/forOwn';

export function formatKeyValue(value) {
    let entries = [];

    forOwn(value, (value, key) => {
        entries.push(`${key}:${value}`);
    });

    return entries.join(', ');
}
