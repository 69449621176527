import React, { useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { withI18n, Trans, i18nMark } from '@lingui/react';
import { useQuery } from 'react-query';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaRedo } from 'react-icons/fa';
import { DataTable, useDataTableState } from '@ingruz/tabulisk';
import parse from 'date-fns/parse';
import differenceInDays from 'date-fns/difference_in_days';
import format from 'date-fns/format';
import queryString from 'query-string';
import clsx from 'classnames';
import find from 'lodash/find';
// import sortBy from 'lodash/sortBy';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import toPairs from 'lodash/toPairs';
import isArray from 'lodash/isArray';
import upperFirst from 'lodash/upperFirst';
import isString from 'lodash/isString';

import { getAvailableExchangeFormats, getFilteredExports, startExport } from '../api';
import { selectors } from '../reducers/filtersReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { selectors as collectionSelectors } from '../reducers/collectionsReducer';
import SubmitButton from './form/SubmitButton';
import SelectField from './form/SelectField';
// import InputField from './form/InputField';
import TextareaField from './form/TextareaField';
import Loader from './utils/Loader';
import { SelectBrandsMultipleField } from './form/SelectBrandsMultiple';
import ActiveFiltersTagsList from './ActiveFiltersTagsList';
import ExportHeaderModal from './ExportHeaderModal';
import * as c from '../constants';
import { subDays } from 'date-fns';

function getStatusLabel(status, detail) {
    if (detail === 'ERRORE') {
        return "Errore nell'elaborazione";
    }

    switch (status) {
        case 'ended':
            return 'Completata';
        case 'running':
            return 'In corso';
        case 'starting':
            return 'In attesa';
        default:
            return '-';
    }
}

function keyToLabel(key) {
    switch (key) {
        case 'marche':
            return 'Marche';
        case 'product_tree':
            return 'Tassonomia';
        case 'q':
            return 'Ricerca libera';
        default:
            return upperFirst(key);
    }
}

function formatEtimFeatures(features) {
    return features
        .map((f) => {
            const label = f.name;

            let value = '';

            switch (f.type) {
                case c.ATTRIBUTE_TYPE_ALPHANUMERIC:
                    value = f.value.join(', ');
                    break;
                case c.ATTRIBUTE_TYPE_LOGIC:
                    value = f.value ? 'Si' : 'No';
                    break;
                case c.ATTRIBUTE_TYPE_NUMERIC:
                    value = f.value;
                    break;
                case c.ATTRIBUTE_TYPE_RANGE:
                    value = `${f.value[0][0]} - ${f.value[0][1]}`;
                    break;
                default:
                    value = f.value;
            }

            return `${label}: ${value}`;
        })
        .join(', ');
}

function formatFilters(filters) {
    return toPairs(filters).map(([key, value]) => {
        if (key === 'pre_classification_roots') {
            return null;
        }

        const textValue = isArray(value)
            ? isString(value[0])
                ? value.join(', ')
                : formatEtimFeatures(value)
            : value;

        // TODO: per valori ETIM usare qualcosa tipo funzione getEtimValue di ItemDataCategory.js o formatAttributeValue di filtersReducer.js

        return (
            <span key={key}>
                <b>{keyToLabel(key)}</b>: {textValue}
            </span>
        );
    });
}

function validate(values, type) {
    const mandatoryFieldError = i18nMark('validation:error:mandatory:field');
    let errors = {};

    // console.log(values);

    if (!values.exchange_format) {
        errors.exchange_format = mandatoryFieldError;
    }

    if (type === 'Marca') {
        if (!values.brands || values.brands.length === 0) {
            errors.brands = mandatoryFieldError;
        }
    }

    if (type === 'Collezione') {
        if (!values.collection) {
            errors.collection = mandatoryFieldError;
        }
    }

    // console.log(errors);

    return errors;
}

function PaginatorInfo({ page, pageCount, total }) {
    return (
        <>
            Pagina {page} di {pageCount} ({total} risultati totali)
        </>
    );
}

function ExportsPanel(props) {
    const qs = queryString.parse(props.location.search);

    const [exportType, setExportType] = useState(qs.type || 'Marca');
    const [showHeaderModal, setShowHeaderModal] = useState(false);
    const [availableExchangeFormats, setAvailableExchangeFormats] = useState([]);
    const [selectedExchangeFormat, setSelectedExchangeFormat] = useState(null);
    const [exportNotes, setExportNotes] = useState(null);
    let formRef = useRef(null);

    const collectionId = qs.collectionId;

    // console.log(exportType);

    // console.log(props);

    const collections = props.collections.toJS().map((c) => {
        return {
            value: c.id,
            label: c.descrizione,
        };
    });

    const selectedCollection = useMemo(() => {
        return find(collections, { value: collectionId });
    }, [collectionId]);

    const initialFormValues = useMemo(() => {
        if (selectedCollection) {
            return {
                collection: selectedCollection,
            };
        }

        if (exportType === 'Marca') {
            return {
                brands: [],
            };
        }

        return {};
    }, [selectedCollection, exportType]);

    // console.log(initialFormValues);

    // console.log(collections);

    const dateFrom = subDays(new Date(), props.exportsListDaysLimit);

    const queryFilters = {
        started_from: format(dateFrom, 'YYYY-MM-DD'),
        started_to: format(new Date(), 'YYYY-MM-DD'),
    };

    const {
        // isLoading,
        isFetching,
        data: exportsData,
        refetch,
    } = useQuery(['filteredExports', queryFilters], getFilteredExports, {
        refetchOnWindowFocus: false,
    });

    useEffect(async () => {
        const exchangeFormatsRes = await getAvailableExchangeFormats();

        setAvailableExchangeFormats(exchangeFormatsRes.data);
    }, []);

    const onSubmit = async (data) => {
        // console.log(availableExchangeFormats);
        // console.log(data);

        const currentExchangeFormat = find(availableExchangeFormats, {
            code: data.exchange_format.value,
        });

        if (currentExchangeFormat.header.length > 0) {
            setShowHeaderModal(true);
            setSelectedExchangeFormat(currentExchangeFormat);
            setExportNotes(data.notes || null);
        } else {
            await onSubmitProper(data);
        }
    };

    const onSubmitProper = async (data, onlyHeader = false) => {
        let payload = data;

        let filter = {};

        // console.log(formRef.current.getState());
        // console.log(data);

        if (exportType === 'Marca') {
            const baseFormState = formRef.current.getState();

            filter = {
                sigle_marca: baseFormState.values.brands,
            };
        } else if (exportType === 'Selezione') {
            filter = omit(props.apiPayload, [
                'product_tree',
                'locale',
                'page',
                'per_page',
                'include_ricambi',
                'include_accessori',
                'sort',
                'sort_direction',
            ]);
        } else if (exportType === 'Collezione') {
            const baseFormState = formRef.current.getState();

            filter = {
                collezione: baseFormState.values.collection.value,
            };
        }

        if (filter && filter.sigle_marca && filter.sigle_marca.length > 1) {
            if (selectedExchangeFormat.multimarca === false) {
                toast(
                    `Il formato selezionato (${selectedExchangeFormat.name}) non supporta l'export di più marche. Selezionarne uno con la dicitura (multimarca) e riprovare!`,
                    {
                        position: 'bottom-right',
                        type: toast.TYPE.ERROR,
                    }
                );
            }

            return;
        }

        if (onlyHeader) {
            payload = {
                code: selectedExchangeFormat.code,
                filter,
                notes: exportNotes,
                header: mapValues(data, (v) => {
                    return isObject(v) ? v.value : v;
                }),
            };
        }

        // console.log(payload);

        // return;

        await startExport(payload);

        toast('Richiesta di export effettuata correttamente', {
            position: 'bottom-right',
            type: toast.TYPE.SUCCESS,
            autoClose: 10000,
        });

        formRef.current.restart();

        refetch();
    };

    const columns = React.useMemo(() => {
        return [
            {
                accessor: 'started_at',
                Header: <Trans id="request time" />,
            },
            {
                accessor: 'info.name_channel',
                Header: <Trans id="exchange format" />,
            },
            {
                accessor: 'filter',
                Header: <Trans id="active filters" />,
                disableSortBy: true,
                Cell: ({ value }) => {
                    return <div className="flex space-x-2">{formatFilters(value)}</div>;
                },
            },
            {
                accessor: 'asset',
                Header: <Trans id="asset" />,
            },
            {
                accessor: 'notes',
                Header: <Trans id="note" />,
                disableSortBy: true,
            },
            {
                accessor: 'status',
                Header: <Trans id="status" />,
                Cell: ({ value, row }) => getStatusLabel(value, row.original.info.detail),
            },
            {
                accessor: 'download',
                Header: <Trans id="download" />,
                disableSortBy: true,
                Cell: ({ row }) => {
                    if (row.original.output) {
                        return (
                            <a className="text-primary" href={row.original.output} target="_parent">
                                <Trans id="download" />
                            </a>
                        );
                    }

                    return null;
                },
            },
        ];
    }, []);

    const initialSortBy = useMemo(() => {
        return [
            {
                id: 'started_at',
                desc: true,
            },
        ];
    }, []);

    const reducer = useDataTableState({
        sortBy: [
            {
                id: 'started_at',
                desc: true,
            },
        ],
    });

    // console.log(reducer);

    return (
        <div className="grid grid-cols-12 mx-2">
            {showHeaderModal && (
                <ExportHeaderModal
                    onClose={() => setShowHeaderModal(false)}
                    exchangeFormat={selectedExchangeFormat}
                    onSubmit={onSubmitProper}
                />
            )}
            <div className="col-span-12 text-center mt-2">
                <h2 className="text-primary">Download</h2>
            </div>
            <div className="col-span-12 xl:col-span-6 xl:col-start-4">
                <div className="grid grid-cols-3 gap-2">
                    <div
                        className={clsx('export-type-btn', {
                            'export-type-current': exportType === 'Marca',
                        })}
                        onClick={() => setExportType('Marca')}
                    >
                        <Trans id="export brands" />
                    </div>
                    <div
                        className={clsx('export-type-btn', {
                            'export-type-current': exportType === 'Collezione',
                        })}
                        onClick={() => setExportType('Collezione')}
                    >
                        <Trans id="export lists" />
                    </div>
                    <div
                        className={clsx('export-type-btn', {
                            'export-type-current': exportType === 'Selezione',
                            // 'export-type-disabled': props.isAnyFilterActive === false,
                        })}
                        // onClick={() => (props.isAnyFilterActive ? setExportType('Selezione') : {})}
                        onClick={() => setExportType('Selezione')}
                    >
                        <Trans id="export selection" />
                    </div>
                </div>
                {exportType === 'Selezione' && (
                    <div className="bg-gray p-2 min-h-8 mt-2 flex">
                        <b className="text-primary mr-4 capitalize">
                            <Trans id="active filters" />
                        </b>
                        <ActiveFiltersTagsList showClearBtn={false} />
                    </div>
                )}
                {exportType && (
                    <div>
                        <Form
                            onSubmit={onSubmit}
                            validate={(values) => validate(values, exportType)}
                            initialValues={initialFormValues}
                            render={({
                                handleSubmit,
                                pristine,
                                hasValidationErrors,
                                submitError,
                                submitting,
                                errors,
                                touched,
                                form,
                                ...rest
                            }) => {
                                if (form) {
                                    formRef.current = form;
                                }

                                return (
                                    <form
                                        // ref={formRef}
                                        onSubmit={handleSubmit}
                                        className="form-vertical text-primary"
                                    >
                                        <div className="grid grid-cols-2 gap-2 mb-2 mt-4">
                                            {exportType === 'Marca' && (
                                                <div className="col-span-2 xl:col-span-1">
                                                    <Field
                                                        name="brands"
                                                        component={SelectBrandsMultipleField}
                                                        valueKey="code"
                                                    />
                                                </div>
                                            )}
                                            {exportType === 'Collezione' && (
                                                <div className="col-span-2 xl:col-span-1">
                                                    <Field
                                                        name="collection"
                                                        component={SelectField}
                                                        options={collections}
                                                        layout="vertical"
                                                        label="Seleziona una lista"
                                                        placeholder="Seleziona una lista"
                                                        className="form-input input-lg"
                                                    />
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    exportType !== 'Selezione'
                                                        ? 'col-span-2 xl:col-span-1'
                                                        : 'col-span-2'
                                                }
                                            >
                                                <Field
                                                    name="exchange_format"
                                                    component={SelectField}
                                                    options={availableExchangeFormats.map((e) => {
                                                        let label = e.name;

                                                        if (e.multimarca) {
                                                            label += ' (multimarca)';
                                                        }

                                                        return {
                                                            value: e.code,
                                                            label,
                                                        };
                                                    })}
                                                    layout="vertical"
                                                    placeholder={i18nMark('select exchange format')}
                                                    className="form-input input-lg"
                                                    disabled={
                                                        exportType === 'Selezione' &&
                                                        !props.isAnyFilterActive
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="mb-2">
                                            <Field
                                                name="notification_email"
                                                component={InputField}
                                                layout="vertical"
                                                placeholder={i18nMark('mail notify download ready')}
                                                className="form-input input-lg"
                                            />
                                        </div> */}
                                        <div className="mb-4">
                                            <Field
                                                name="notes"
                                                component={TextareaField}
                                                layout="vertical"
                                                placeholder={i18nMark('note')}
                                                className="form-input input-lg"
                                                disabled={
                                                    exportType === 'Selezione' &&
                                                    !props.isAnyFilterActive
                                                }
                                            />
                                        </div>
                                        <SubmitButton
                                            submitting={submitting}
                                            disabled={
                                                pristine ||
                                                (exportType === 'Selezione' &&
                                                    !props.isAnyFilterActive)
                                            }
                                        >
                                            <Trans id="request" />
                                        </SubmitButton>
                                    </form>
                                );
                            }}
                        />
                    </div>
                )}
            </div>
            <div className="col-span-12 mt-12">
                <div className="text-primary text-xl">
                    <Trans id="request status" />
                </div>
                <div className="flex items-center mb-1">
                    <button
                        className="btn btn-link mr-0.5"
                        onClick={refetch}
                        disabled={exportType === null}
                    >
                        <FaRedo />
                    </button>
                    <span className="text-primary">
                        <Trans id="click the icon to refresh" />
                    </span>
                </div>
                {exportType !== null && (
                    <DataTable
                        data={
                            isFetching
                                ? []
                                : exportsData.data.results.filter((row) => {
                                      if (row.type === null || row.type !== exportType) {
                                          return false;
                                      }

                                      // console.log(row);

                                      const date = parse(
                                          row.started_at,
                                          'yyyy-MM-dd HH:mm:ss',
                                          new Date()
                                      );

                                      const diff = differenceInDays(new Date(), date);

                                      return diff <= props.exportsListDaysLimit || 30;
                                  })
                        }
                        columns={columns}
                        useReducer={reducer}
                        config={{
                            useFlex: false,
                            remote: false,
                            paginate: true,
                            // debug: true,
                            skipAutoReset: true,
                            showPageSizeSelector: false,
                            PaginatorInfoComponent: PaginatorInfo,
                            noRowsMessage: isFetching ? (
                                <Loader />
                            ) : (
                                <div className="bg-gray py-2 text-center">
                                    <Trans
                                        id="no export in the last n days"
                                        values={{ count: props.exportsListDaysLimit }}
                                    />
                                </div>
                            ),
                            classNames: {
                                tableClassName: 'table table-striped table-hover table-dense',
                            },
                            initialSortBy,
                        }}
                    />
                )}
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        apiPayload: selectors.getApiPayload(state),
        isAnyFilterActive: selectors.isAnyFilterActive(state),
        exportsListDaysLimit: appSelectors.getExportsListDaysLimit(state),
        collections: collectionSelectors.getDisplayItems(state),
        // collectionEngagedId: collectionSelectors.getEngagedCollection(state),
        // collectionShown: collectionSelectors.getShownCollection(state),
        // lastSelectedCollection: collectionSelectors.getLastSelectedCollection(state),
    };
}

const ConnectedExportsPanel = connect(mapStateToProps)(ExportsPanel);

const RouterConnectedExportsPanel = withRouter(ConnectedExportsPanel);

export default withI18n()(RouterConnectedExportsPanel);
