import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import cx from 'classnames';

export default function ScrollToTopButton({ scrollContainer, inverse }) {
    return (
        <button
            className={cx('btn btn-action circle', {
                'btn-primary': !inverse
            })}
            onClick={() =>
                scroll.scrollTo(0, {
                    containerId: scrollContainer,
                    duration: 500,
                    delay: 50,
                    smooth: 'easeInOutQuint'
                })
            }
        >
            <i className="icon icon-arrow-up" />
        </button>
    );
}

ScrollToTopButton.defaultProps = {
    inverse: false
};
