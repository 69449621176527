import React from 'react';
import { connect } from 'react-redux';
import { withI18n } from '@lingui/react';
import toPairs from 'lodash/toPairs';
import isBoolean from 'lodash/isBoolean';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

import ProductClassFilterActive from './ProductClassFilterActive';
import { selectors } from '../reducers/productClassesReducer';
// import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { selectors as filtersSelectors } from '../reducers/filtersReducer';
import { getLocaleFromLanguageCode } from '../intl-helpers';

function ActiveFilterLabel({ label, onClear }) {
    return (
        <span className="label label-secondary" style={{ marginRight: '5px' }}>
            {label}{' '}
            {onClear && (
                <i
                    onClick={onClear}
                    className="icon icon-cross c-hand"
                    style={{
                        position: 'relative',
                        top: '-2px',
                    }}
                />
            )}
        </span>
    );
}

export function ActiveFiltersTagsList({
    activeFilters,
    attributes,
    globalActiveFilters,
    handleRemoveFilter,
    i18n,
    language,
    onAttributeResetClick,
    onChangeBooleanField,
    onCheckboxToggle,
    productClassFilter,
    isFetching,
    showClearBtn = true,
}) {
    const langCode = getLocaleFromLanguageCode(language);

    const active = productClassFilter.value !== null;
    const showLoader = active && isFetching;

    return (
        <>
            <div>
                {toPairs(globalActiveFilters).map(([key, acFilter]) => {
                    // console.log(key, acFilter);

                    if (!acFilter) {
                        return null;
                    }

                    if (key === 'sector') {
                        return (
                            <React.Fragment key={key}>
                                <span className="text-bold" key={`${key}_label`}>
                                    {acFilter.taxonomy.label[langCode]}:
                                </span>{' '}
                                <ActiveFilterLabel
                                    key={key}
                                    label={acFilter.value.name[langCode]}
                                    onClear={
                                        showClearBtn
                                            ? () => handleRemoveFilter(key, acFilter.value.id)
                                            : null
                                    }
                                />
                            </React.Fragment>
                        );
                    }

                    if (!isArray(acFilter) && isObject(acFilter)) {
                        return (
                            <React.Fragment key={key}>
                                <span className="text-bold" key={`${key}_label`}>
                                    {i18n._(key)}:
                                </span>{' '}
                                <ActiveFilterLabel
                                    key={key}
                                    label={
                                        isObject(acFilter.label)
                                            ? acFilter.label[langCode]
                                            : i18n._(acFilter.label)
                                    }
                                    onClear={
                                        showClearBtn ? () => handleRemoveFilter(key, null) : null
                                    }
                                />
                            </React.Fragment>
                        );
                    }

                    if (isString(acFilter) || isBoolean(acFilter)) {
                        return (
                            <React.Fragment key={key}>
                                <span className="text-bold" key={`${key}_label`}>
                                    {i18n._(key)}:
                                </span>{' '}
                                <ActiveFilterLabel
                                    key={key}
                                    label={isBoolean(acFilter) ? 'Si' : acFilter}
                                    onClear={
                                        showClearBtn ? () => handleRemoveFilter(key, null) : null
                                    }
                                />
                            </React.Fragment>
                        );
                    }

                    if (acFilter.length === 0) {
                        return null;
                    }

                    if (key === 'priceRange') {
                        return (
                            <React.Fragment key={key}>
                                <span className="text-bold" key={`${key}_label`}>
                                    {i18n._(key)}:
                                </span>{' '}
                                <ActiveFilterLabel
                                    key={key}
                                    label={acFilter.join(' - ')}
                                    onClear={
                                        showClearBtn ? () => handleRemoveFilter(key, null) : null
                                    }
                                />
                            </React.Fragment>
                        );
                    }

                    return (
                        <React.Fragment key={key}>
                            <span className="text-bold" key={`${key}_label`}>
                                {i18n._(key)}:
                            </span>{' '}
                            <>
                                {acFilter.map((option) => {
                                    if (!option) {
                                        return null;
                                    }

                                    return (
                                        <ActiveFilterLabel
                                            key={option.value}
                                            label={option.label[langCode]}
                                            onClear={
                                                showClearBtn
                                                    ? () => handleRemoveFilter(key, option.value)
                                                    : null
                                            }
                                        />
                                    );
                                })}
                            </>
                        </React.Fragment>
                    );
                })}
            </div>
            {!showLoader && active && (
                <ProductClassFilterActive
                    activeFilters={activeFilters}
                    language={language}
                    attributes={attributes}
                    showHeader={active}
                    onAttributeResetClick={onAttributeResetClick}
                    onCheckboxToggle={onCheckboxToggle}
                    onChangeBooleanField={onChangeBooleanField}
                    showClearBtn={showClearBtn}
                />
            )}
        </>
    );
}

function mapStateToProps(state) {
    return {
        isFetching: selectors.getIsFetching(state),
        activeFilters: selectors.getProductClassAttributesActiveFilters(state),
        globalActiveFilters: filtersSelectors.getActiveFiltersDetails(state),
        attributes: selectors.getProductClassAttributesList(state),
        language: appSelectors.getLanguage(state),
        productClassFilter: selectors.getFilter(state),
        // validFilterOptions: getValidFilterOptions(selectedNode)
    };
}

export default connect(mapStateToProps)(withI18n()(ActiveFiltersTagsList));
