import get from 'lodash/get';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import languages from './languages_config';

// const languages = {
//     it_IT: {
//         locale: 'it_IT',
//         label: 'Italiano',
//         short_code: 'it'
//     },
//     en_GB: {
//         locale: 'en_GB',
//         label: 'English',
//         short_code: 'en'
//     },
//     ru_RU: {
//         locale: 'ru_RU',
//         label: 'русский',
//         short_code: 'ru'
//     },
//     es_ES: {
//         locale: 'es_ES',
//         label: 'Español',
//         short_code: 'es'
//     }
// };

export function getLocaleFromLanguageCode(code) {
    const language = find(languages, { short_code: code });

    if (language) {
        return language.locale;
    }

    return 'it_IT';
}

export function getIntlNumberFormat(locale = 'it-IT', options = {}) {
    return new Intl.NumberFormat(locale, options);
}

export function getOptionLabelLocalized(language) {
    const locale = getLocaleFromLanguageCode(language);

    return function(option) {
        if (!option) {
            return '';
        }

        let label = get(option, `label.${locale}`);

        if (label) {
            return label;
        }

        return get(option, 'label.key', `[${option.code}]`);
    };
}

export function getShortCodeFromLocale(locale) {
    return get(languages, `${locale}.short_code`);
}

export function getLanguageLabelFromLocale(locale) {
    return get(languages, `${locale}.label`, locale);
}

export function getOptionLocaleFromCode(code) {
    return {
        value: code,
        label: getLanguageLabelFromLocale(code)
    };
}

export function getLocalizedTextWithFallback(labels, locale) {
    if (!labels) {
        return null;
    }

    return get(labels, locale, labels.key);
}

export const supportedLanguages = Object.values(languages).map(lang => lang.short_code);

export const getMessageFromI18nCatalog = (language, msgId) => {
    // TODO: in futuro forse mi converrà instanziare un oggetto i18n da lingui-core
    if (isUndefined(window.DATAPOOL_i18n_CATALOGS)) {
        return msgId;
    }

    if (isUndefined(window.DATAPOOL_i18n_CATALOGS[language])) {
        return msgId;
    }

    if (isUndefined(window.DATAPOOL_i18n_CATALOGS[language].messages[msgId])) {
        return msgId;
    }

    return window.DATAPOOL_i18n_CATALOGS[language].messages[msgId];
};
