import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { selectors } from '../../reducers/userReducer';

function mapStateToProps(state) {
    return {
        user: selectors.getUser(state)
    };
}

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function MatchWhenAuthorized(WrappedComponent) {
    function MatchWhenAuthorizedComponent(props) {
        if (props.user !== null) {
            return <WrappedComponent {...props} />;
        }

        return <Redirect to="/login" />;
    }

    MatchWhenAuthorizedComponent.displayName = getDisplayName(WrappedComponent);

    return connect(mapStateToProps)(MatchWhenAuthorizedComponent);
}

export default MatchWhenAuthorized;
