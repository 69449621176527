import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/react';
import { Link } from 'react-router-dom';

import AppLogo from './utils/AppLogo';

export default function ErrorPage({ message, showBackButton, showReloadButton }) {
    return (
        <div className="bg-primary login-wrapper">
            <div className="container">
                <div className="columns">
                    <div className="column col-md-12 col-xl-6 col-4 col-mx-auto">
                        <div className="form-spaced">
                            <div className="text-center" style={{ marginBottom: '16px' }}>
                                <AppLogo style={{ margin: 'auto' }} />
                            </div>
                            <div className="text-large text-primary text-center">
                                {message ? message : <Trans id="error:unexpected" />}
                            </div>
                            {showBackButton && (
                                <div className="text-center mt-2">
                                    <Link className="btn btn-primary" to="/app">
                                        <i className="icon icon-back" />{' '}
                                        <Trans id="Back to application" />
                                    </Link>
                                </div>
                            )}
                            {showReloadButton && (
                                <div className="text-center mt-2">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => window.location.reload()}
                                    >
                                        <i className="icon icon-refresh" /> <Trans id="reset" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ErrorPage.propTypes = {
    message: PropTypes.string,
    showBackButton: PropTypes.bool,
    showReloadButton: PropTypes.bool,
};

ErrorPage.defaultProps = {
    showBackButton: true,
    showReloadButton: false,
};
